import { Component, Input } from '@angular/core';
import {
  OTHER_SYSTEM_TYPE,
  OtherSystemDetailsDto,
} from '@app/models/registration-form';
import { ROUTES } from '@app/models/routes.enum';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-other-system-details-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './other-system-details-summary.component.html',
})
export class OtherSystemDetailsSummaryComponent {
  @Input({ required: true })
  public otherSystemDetails!: OtherSystemDetailsDto;
  public readonly route = ROUTES.OTHER_2_SYSTEM_DETAILS;
  public readonly OTHER_SYSTEM_TYPE = OTHER_SYSTEM_TYPE;
}
