import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import {
  CONTROLLABLE_CONSUMPTION_SYSTEM,
  FORM_OF_FEED_IN_SALE,
  PV_CONTROL,
  PV_OPERATOR,
  PVCustomerFacilityDto,
} from '@app/models/registration-form';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { distinctUntilChanged, filter, map, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControllableConsumptionSystemsMapper } from './controllable-consumption-systems-mapper';
import { DateHelperService } from '@app/services/date-helper.service';

export enum PV_FACILITY_FORM {
  OPERATOR = 'operator',
  CONTROL = 'control',
  CONTROLLABLE_CONSUMPTION_SYSTEMS = 'controllableConsumptionSystems',
  PLANNED_COMMISSIONING_DATE = 'plannedCommissioningDate',
  FORM_OF_FEED_IN_SALE = 'formOfFeedInSale',
}
@Component({
  selector: 'app-pv-facility-information',
  templateUrl: './pv-facility-information.component.html',
})
export class PvFacilityInformationComponent implements OnInit {
  readonly #fb = inject(FormBuilder);
  readonly #appStateService = inject(AppStateService);
  readonly #routeService = inject(RouteService);
  readonly #dateHelperService = inject(DateHelperService);
  readonly #destroyRef = inject(DestroyRef);

  public customerFacilityForm!: FormGroup;
  public readonly PV_FACILITY_FORM = PV_FACILITY_FORM;

  public readonly controllableConsumptionSystemsOptions = Object.keys(
    CONTROLLABLE_CONSUMPTION_SYSTEM
  );
  public readonly formOfFeedInSaleOptions = Object.keys(FORM_OF_FEED_IN_SALE);
  public readonly pvControlOptions = Object.keys(PV_CONTROL);
  public readonly pvOperatorOptions = Object.keys(PV_OPERATOR);
  public readonly minDate = this.#dateHelperService.getFutureDate(1);
  public allTouched = false;

  public ngOnInit(): void {
    this.#createPvFacilityInformationForm();
    this.#updateForm();
    this.#watchForm();
  }

  #createPvFacilityInformationForm(): void {
    this.customerFacilityForm = this.#fb.group({
      [PV_FACILITY_FORM.OPERATOR]: [null, Validators.required],
      [PV_FACILITY_FORM.CONTROL]: [null, Validators.required],
      [PV_FACILITY_FORM.CONTROLLABLE_CONSUMPTION_SYSTEMS]: this.#fb.array(
        this.controllableConsumptionSystemsOptions.map(() =>
          this.#fb.control(false)
        ),
        [CustomValidators.atLeastOneChecked()]
      ),
      [PV_FACILITY_FORM.PLANNED_COMMISSIONING_DATE]: [null],
      [PV_FACILITY_FORM.FORM_OF_FEED_IN_SALE]: [null, Validators.required],
    });
  }

  public readControllableConsumptionSystemsControls(): FormArray | undefined {
    return this.customerFacilityForm.get(
      PV_FACILITY_FORM.CONTROLLABLE_CONSUMPTION_SYSTEMS
    ) as FormArray;
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.pvCustomerFacility),
        filter(Boolean),
        take(1),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(pvFacilityInformation =>
        this.customerFacilityForm.patchValue({
          ...pvFacilityInformation,
          controllableConsumptionSystems:
            ControllableConsumptionSystemsMapper.INSTANCE.mapToBooleanArray(
              pvFacilityInformation.controllableConsumptionSystems
            ),
        })
      );
  }

  #watchForm(): void {
    this.customerFacilityForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        distinctUntilChanged(),
        map(value => {
          return {
            ...value,
            controllableConsumptionSystems:
              ControllableConsumptionSystemsMapper.INSTANCE.map(
                this.customerFacilityForm.get(
                  PV_FACILITY_FORM.CONTROLLABLE_CONSUMPTION_SYSTEMS
                )!.value
              ),
          };
        })
      )
      .subscribe((pvFacilityInformation: PVCustomerFacilityDto) => {
        this.#appStateService.updateFormData({
          pvCustomerFacility: pvFacilityInformation,
        });
      });
  }

  public previous(): void {
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.customerFacilityForm.valid) {
      this.#routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.customerFacilityForm.markAllAsTouched();
    }
  }
}
