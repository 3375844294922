import { NgModule } from '@angular/core';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { GridConnectionChangeComponent } from './grid-connection/grid-connection-change/grid-connection-change.component';
import { GridConnectionConsumerComponent } from './grid-connection/grid-connection-consumer/grid-connection-consumer.component';
import { GridConnectionPropertyTypeComponent } from './grid-connection/grid-connection-property-type/grid-connection-property-type.component';
import { GridConnectionTypeComponent } from './grid-connection/grid-connection-type/grid-connection-type.component';
import { PowerChangeDetailsComponent } from './grid-connection/power-change-details/power-change-details.component';
import { HeatPumpComponent } from './heat-pump/heat-pump.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { PvFacilityInformationComponent } from './pv-system/pv-facility-information/pv-facility-information.component';
import { ConstructionElectricityComponent } from './construction-electricity/construction-electricity.component';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';
import { ExceedsLimitPipe } from '@app/shared/pipes/exceeds-limit.pipe';
import { SelectedPvComponent } from './product-selection/components/selected-pv/selected-pv.component';

@NgModule({
  declarations: [
    GridConnectionChangeComponent,
    GridConnectionConsumerComponent,
    GridConnectionPropertyTypeComponent,
    GridConnectionTypeComponent,
    HeatPumpComponent,
    PowerChangeDetailsComponent,
    ProductSelectionComponent,
    PvFacilityInformationComponent,
    ConstructionElectricityComponent,
  ],
  imports: [
    SharedModule,
    SelectedPvComponent,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    RequiredSuffixDirective,
    IsModuleOptionEnabledPipe,
    ExceedsLimitPipe,
  ],
})
export class ElectricityModule {}
