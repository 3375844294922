import { Component, inject, Input } from '@angular/core';
import { getLanguageLocaleCulture } from '@app/constants/language';
import { ChargingDeviceSystemInformation } from '@app/models/form-data.interface';
import { ROUTES } from '@app/models/routes.enum';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-charging-device-system-information-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './charging-device-system-information-summary.component.html',
})
export class ChargingDeviceSystemInformationSummaryComponent {
  readonly #translateService: TranslateService = inject(TranslateService);
  @Input({ required: true })
  public chargingDeviceSystemInformation!: ChargingDeviceSystemInformation;
  public readonly route = ROUTES.CHARGING_DEVICE_SYSTEM_INFORMATION;
  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
}
