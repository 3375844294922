import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import {
  LOAD_MANAGEMENT,
  OTHER_SYSTEM_TYPE,
  OtherSystemDetailsDto,
} from '@app/models/registration-form';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-other-system-details',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    RequiredSuffixDirective,
  ],
  templateUrl: './other-system-details.component.html',
})
export class OtherSystemDetailsComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);

  public form!: FormGroup;
  public allTouched = false;
  public readonly OTHER_SYSTEM_TYPE = Object.values(OTHER_SYSTEM_TYPE);
  public readonly OTHER_SYSTEM_TYPE_OTHER = OTHER_SYSTEM_TYPE.OTHER;
  public readonly LOAD_MANAGEMENT = Object.values(LOAD_MANAGEMENT);

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
    this.#watchForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      systemManufacturer: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemModel: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
          ],
        },
      ],
      systemCount: [null, Validators.required],
      systemType: [null, Validators.required],
      otherSystemType: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      power: [null, Validators.required],
      maxStartingCurrent: [null],
      maxControllablePowerSupply: [null, Validators.required],
      separateMeter: [null, Validators.required],
      meterAvailable: [null, Validators.required],
      meterNumber: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      operationalPurposeOrCriticalInfrastructure: [null, Validators.required],
      loadManagement: [null, Validators.required],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(filter(Boolean), take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(form => {
        if (form.formData.otherSystemDetails) {
          this.form.patchValue(form.formData.otherSystemDetails);
        }
        this.#configureOtherSystemTypeInput();
        this.#configureMeterNumberInput();
      });
  }

  #watchForm(): void {
    this.form
      .get('systemType')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#configureOtherSystemTypeInput());

    this.form
      .get('meterAvailable')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#configureMeterNumberInput());
  }

  #configureOtherSystemTypeInput() {
    if (this.form.get('systemType')?.value === OTHER_SYSTEM_TYPE.OTHER) {
      this.form.get('otherSystemType')?.enable();
    } else {
      this.form.get('otherSystemType')?.disable();
    }
  }

  #configureMeterNumberInput() {
    if (this.form.get('meterAvailable')?.value === true) {
      this.form.get('meterNumber')?.enable();
    } else {
      this.form.get('meterNumber')?.disable();
    }
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState() {
    const otherSystemDetails: OtherSystemDetailsDto = this.form.value;
    this.#appStateService.updateFormData({
      otherSystemDetails: otherSystemDetails,
    });
  }
}
