<app-summary-section
  heading="STORAGE_CUSTOMER_FACILITY.HEADING"
  [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="STORAGE_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE.TITLE"
      [value]="
        (storageCustomerFacility.plannedCommissioningDate
          | date: 'shortDate' : '' : currentLanguageCulture) || undefined
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="STORAGE_CUSTOMER_FACILITY.FORM_OF_FEED_IN_SALE.TITLE"
      [value]="
        'STORAGE_CUSTOMER_FACILITY.FORM_OF_FEED_IN_SALE.' +
          storageCustomerFacility.formOfFeedInSale | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="STORAGE_CUSTOMER_FACILITY.CONTROLLABLE.TITLE"
      [value]="
        (storageCustomerFacility.controllable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>
  </div>
</app-summary-section>
