<app-form-card
  titleIcon="electric_bolt"
  cardTitle="CHARGING_DEVICE_CONTROLLABLE_DETAILS.HEADING"
  dataTestId="charging-device-controllable-system-heading">
  <p>
    {{ 'CHARGING_DEVICE_CONTROLLABLE_DETAILS.DESCRIPTION' | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.TITLE'
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="full-width-flex-element">
            <mat-label
              >{{
                'CHARGING_DEVICE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.LABEL'
                  | translate
              }}
            </mat-label>
            <input
              matInput
              type="number"
              name="totalMinimumPower"
              formControlName="totalMinimumPower"
              min="0"
              data-testId="total-minimum-power" />
            <mat-error appErrorMessages="totalMinimumPower"></mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="compliant"
          aria-labelledby="compliant-radio-group-label"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="compliant-yes">
            {{
              'CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.COMPLIANT'
                | translate
            }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testid="compliant-no">
            {{
              'CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.NOT_COMPLIANT'
                | translate
            }}
          </mat-radio-button>
          <mat-error appErrorMessages="compliant" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controller"
          aria-labelledby="controller-radio-group-label"
          class="input-container">
          @for (controller of CONTROLLER; track controller) {
            <mat-radio-button
              [value]="controller"
              [attr.data-testId]="controller">
              {{
                'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER.' + controller
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="controller" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
        @if (
          form.get('controller')?.value ===
          CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER_NAME'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="controllerName"
                formControlName="controllerName"
                data-testId="controller-name" />
              <mat-error appErrorMessages="controllerName"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER_NAME.TOOLTIP">
            </app-info-tooltip>
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TITLE'
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="existingSystem"
          aria-labelledby="existingSystem-radio-group-label"
          class="input-container">
          @for (
            existingSystem of EXISTING_SYSTEM_OPTIONS;
            track existingSystem
          ) {
            <mat-radio-button
              [value]="existingSystem"
              [attr.data-testId]="existingSystem">
              {{
                'CHARGING_DEVICE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.' +
                  existingSystem | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="existingSystem"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TITLE'
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controlType"
          aria-labelledby="controlType-radio-group-label"
          class="input-container">
          @for (controlType of CONTROL_TYPE; track controlType) {
            <mat-radio-button
              [value]="controlType"
              [attr.data-testId]="controlType">
              {{
                'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROL_TYPE.' +
                  controlType | translate
              }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="controlType" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TITLE'
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="networkFeeModule"
          aria-labelledby="networkFeeModule-radio-group-label"
          class="input-container">
          @for (
            networkFeeModule of NETWORK_FEE_MODULE;
            track networkFeeModule
          ) {
            <mat-radio-button
              [value]="networkFeeModule"
              [attr.data-testId]="networkFeeModule">
              {{
                'CHARGING_DEVICE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.' +
                  networkFeeModule | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="networkFeeModule"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
