<app-summary-section
  heading="CHARGING_DEVICE_INFORMATION.HEADING"
  [stepRoute]="route">
  <p class="section-heading">
    {{ 'CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TITLE' | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.MANUFACTURER"
      [value]="chargingDeviceInformation.systemManufacturer">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TYPE"
      [value]="chargingDeviceInformation.systemType">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.NUMBER_OF_IDENTICAL_CHARGING_DEVICES"
      [value]="chargingDeviceInformation.systemCount">
    </app-summary-section-field>
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.TITLE"
      [value]="
        'CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.' +
          chargingDeviceInformation.type | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.TITLE"
      [value]="
        'CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.' +
          (chargingDeviceInformation.public
            ? 'OPEN_TO_PUBLIC'
            : 'NOT_OPEN_TO_PUBLIC') | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.SPECIAL_RIGHTS.TITLE"
      [value]="
        (chargingDeviceInformation.specialRights ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>
  </div>

  <p class="section-heading">
    {{
      'CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.TITLE' | translate
    }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_AC"
      [value]="chargingDeviceInformation.chargingPointACCount">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_DC"
      [value]="chargingDeviceInformation.chargingPointDCCount">
    </app-summary-section-field>
  </div>

  <p class="section-heading">
    {{ 'CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.TITLE' | translate }}
  </p>
  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER"
      [value]="chargingDeviceInformation.maxGridPower">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_FEED_IN_POWER"
      [value]="chargingDeviceInformation.maxGridFeedInPower">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.POWER"
      [value]="chargingDeviceInformation.systemPower">
    </app-summary-section-field>
  </div>

  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TITLE"
      [value]="
        (chargingDeviceInformation.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.TITLE"
      [value]="
        (chargingDeviceInformation.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>

    @if (chargingDeviceInformation.meterAvailable) {
      <app-summary-section-field
        label="CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.METER_NUMBER"
        [value]="chargingDeviceInformation.meterNumber">
      </app-summary-section-field>
    }

    <app-summary-section-field
      label="CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.TITLE"
      [value]="
        'CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.' +
          chargingDeviceInformation.loadManagement | translate
      ">
    </app-summary-section-field>
  </div>
</app-summary-section>
