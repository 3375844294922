import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, take } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconInitializerService {
  private _configured$: ReplaySubject<void> = new ReplaySubject(1);
  private loadedIcons$: Observable<SVGElement>[] = [];
  private readonly customIcons = [
    'solar_power_balcony',
    'gas',
    'heat_pump',
    'heat',
    'storage',
    'solar_power',
    'rule_settings',
    'offline_bolt',
    'construction_electricity',
    'sewage',
    'drinking_water',
    'construction_water',
    'electricity_storage',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.load(this.customIcons);
  }

  public get configured$(): Observable<void> {
    return this._configured$.asObservable();
  }

  private load(customIcons: string[]) {
    if (customIcons.length === 0) {
      this._configured$.next();
      this._configured$.complete();
    } else {
      customIcons.forEach((icon: string) => {
        const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/custom-icons/${icon}.svg`
        );
        this.addIcon(icon, safeUrl);
        this.addIconForPreloading(safeUrl);
      });
      forkJoin(this.loadedIcons$)
        .pipe(take(1))
        .subscribe(() => {
          this._configured$.next();
          this._configured$.complete();
        });
    }
  }

  private addIcon(icon: string, url: SafeUrl): void {
    this.matIconRegistry.addSvgIcon(icon, url);
  }

  private addIconForPreloading(url: SafeUrl): void {
    const svgIconFromUrl = this.matIconRegistry.getSvgIconFromUrl(url);
    this.loadedIcons$.push(svgIconFromUrl);
  }
}
