import { Component, inject, Input } from '@angular/core';
import { ROUTES } from '@app/models/routes.enum';
import { OtherCustomerFacility } from '@app/models/form-data.interface';
import { SharedModule } from '@app/modules/shared/shared.module';
import { getLanguageLocaleCulture } from '@app/constants/language';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-other-customer-facility-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './other-customer-facility-summary.component.html',
})
export class OtherCustomerFacilitySummaryComponent {
  readonly #translateService: TranslateService = inject(TranslateService);
  @Input({ required: true })
  public otherCustomerFacility!: OtherCustomerFacility;
  public readonly route = ROUTES.OTHER_2_CUSTOMER_FACILITY;

  public readonly currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );
}
