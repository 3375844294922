import { Component, Input } from '@angular/core';
import { ChargingDeviceInformation } from '@app/models/form-data.interface';
import { ROUTES } from '@app/models/routes.enum';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-charging-device-information-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './charging-device-information-summary.component.html',
})
export class ChargingDeviceInformationSummaryComponent {
  @Input({ required: true })
  public chargingDeviceInformation!: ChargingDeviceInformation;
  public readonly route = ROUTES.CHARGING_DEVICE_INFORMATION;
}
