<app-summary-section
  heading="CHARGING_DEVICE_CONTROLLABLE_DETAILS.HEADING"
  [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.TITLE"
      [value]="controllableDetails.totalMinimumPower">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.TITLE"
      [value]="
        'CHARGING_DEVICE_CONTROLLABLE_DETAILS.COMPLIANCE.' +
          (controllableDetails.compliant ? 'COMPLIANT' : 'NOT_COMPLIANT')
          | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER.TITLE"
      [value]="
        'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER.' +
          controllableDetails.controller | translate
      ">
    </app-summary-section-field>

    @if (
      controllableDetails.controller ===
      CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR
    ) {
      <app-summary-section-field
        label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROLLER_NAME"
        [value]="controllableDetails.controllerName">
      </app-summary-section-field>
    }

    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TITLE"
      [value]="
        'CHARGING_DEVICE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.' +
          controllableDetails.existingSystem | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TITLE"
      [value]="
        'CHARGING_DEVICE_CONTROLLABLE_DETAILS.CONTROL_TYPE.' +
          controllableDetails.controlType | translate
      ">
    </app-summary-section-field>

    <app-summary-section-field
      label="CHARGING_DEVICE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TITLE"
      [value]="
        'CHARGING_DEVICE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.' +
          controllableDetails.networkFeeModule | translate
      ">
    </app-summary-section-field>
  </div>
</app-summary-section>
