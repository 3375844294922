import { Component, Input } from '@angular/core';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { ROUTES } from '@app/models/routes.enum';
import {
  StorageDetailsDto,
  UNIT_COUPLING,
} from '@app/models/registration-form';

@Component({
  selector: 'app-storage-details-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './storage-details-summary.component.html',
})
export class StorageDetailsSummaryComponent {
  @Input({ required: true })
  public storageDetails!: StorageDetailsDto;
  public route = ROUTES.STORAGE_DETAILS;
  public UNIT_COUPLING = UNIT_COUPLING;
}
