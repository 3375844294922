<app-form-card
  titleIcon="electric_bolt"
  cardTitle="CHARGING_DEVICE_INFORMATION.HEADING"
  dataTestId="charging-device-information-heading">
  <p>
    {{ 'CHARGING_DEVICE_INFORMATION.DESCRIPTION' | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 appRequiredSuffix>
          {{
            'CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TITLE' | translate
          }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.MANUFACTURER'
                | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              name="systemManufacturer"
              formControlName="systemManufacturer"
              data-testid="system-manufacturer" />
            <mat-error appErrorMessages="systemManufacturer"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.MANUFACTURER_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TYPE'
                | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              name="systemType"
              formControlName="systemType"
              data-testid="system-type" />
            <mat-error appErrorMessages="systemType"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.TYPE_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.NUMBER_OF_IDENTICAL_CHARGING_DEVICES'
                | translate
            }}</mat-label>

            <input
              matInput
              type="number"
              name="systemCount"
              formControlName="systemCount"
              min="0"
              data-testid="system-count" />
            <mat-error appErrorMessages="systemCount"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.CHARGING_DEVICE_DATA.NUMBER_OF_IDENTICAL_CHARGING_DEVICES_TOOLTIP">
          </app-info-tooltip>
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              'CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.TITLE'
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="type"
          class="input-container">
          @for (
            chargingDeviceType of chargingDeviceTypes;
            track chargingDeviceType
          ) {
            <mat-radio-button
              [value]="chargingDeviceType"
              [attr.data-testId]="chargingDeviceType"
              >{{
                'CHARGING_DEVICE_INFORMATION.TYPE_OF_CHARGING_DEVICE.' +
                  chargingDeviceType | translate
              }}</mat-radio-button
            >
          }
          <mat-error
            appErrorMessages="type"
            [hideError]="!allTouched"></mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              'CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.TITLE' | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="public"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="open-to-public">{{
            'CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.OPEN_TO_PUBLIC'
              | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="not-open-to-public">
            {{
              'CHARGING_DEVICE_INFORMATION.ACCESSIBILITY.NOT_OPEN_TO_PUBLIC'
                | translate
            }}
          </mat-radio-button>

          <mat-error
            appErrorMessages="public"
            [hideError]="!allTouched"></mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              'CHARGING_DEVICE_INFORMATION.SPECIAL_RIGHTS.TITLE' | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.SPECIAL_RIGHTS.TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="specialRights"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="special-rights">{{
            'COMMON.YES' | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="no-special-rights">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="specialRights"
            [hideError]="!allTouched"></mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 appRequiredSuffix>
          {{
            'CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.TITLE'
              | translate
          }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_AC'
                | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="chargingPointACCount"
              formControlName="chargingPointACCount"
              min="0"
              data-testid="charging-point-AC-count" />
            <mat-error appErrorMessages="chargingPointACCount"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_AC_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_DC'
                | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="chargingPointDCCount"
              formControlName="chargingPointDCCount"
              min="0"
              data-testid="charging-point-DC-count" />
            <mat-error appErrorMessages="chargingPointDCCount"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.NUMBER_OF_CHARGING_POINTS.NUMBER_IN_DC_TOOLTIP">
          </app-info-tooltip>
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 appRequiredSuffix>
          {{ 'CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.TITLE' | translate }}
        </h2>
        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER'
                | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="maxGridPower"
              formControlName="maxGridPower"
              min="0"
              data-testid="max-grid-power" />
            <mat-error appErrorMessages="maxGridPower"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_POWER_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_FEED_IN_POWER'
                | translate
            }}</mat-label>
            <input
              matInput
              type="number"
              name="maxGridFeedInPower"
              formControlName="maxGridFeedInPower"
              min="0"
              data-testid="max-grid-feed-in-power" />
            <mat-error appErrorMessages="maxGridFeedInPower"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.MAX_GRID_FEED_IN_POWER_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>{{
              'CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.POWER' | translate
            }}</mat-label>

            <input
              matInput
              type="number"
              name="systemPower"
              formControlName="systemPower"
              min="0"
              data-testid="system-power" />
            <mat-error appErrorMessages="systemPower"></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="CHARGING_DEVICE_INFORMATION.PERFORMANCE_DATA.POWER_TOOLTIP">
          </app-info-tooltip>
        </div>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              'CHARGING_DEVICE_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TITLE'
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.MEASUREMENT_VIA_SEPARATE_METER.TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="separateMeter"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="separate-meter">{{
            'COMMON.YES' | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="no-separate-meter">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="separateMeter"
            [hideError]="!allTouched"></mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>
            {{
              'CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.TITLE'
                | translate
            }}</span
          >
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.TOOLTIP">
          </app-info-tooltip>
        </h2>

        <mat-radio-group
          color="primary"
          formControlName="meterAvailable"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="meter-available">{{
            'COMMON.YES' | translate
          }}</mat-radio-button>
          <mat-radio-button [value]="false" data-testid="meter-not-available">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
          <mat-error
            appErrorMessages="meterAvailable"
            [hideError]="!allTouched"></mat-error>
        </mat-radio-group>

        @if (form.get('meterAvailable')?.value) {
          <div class="input-container">
            <mat-form-field
              appearance="outline"
              class="full-width-flex-element">
              <mat-label
                >{{
                  'CHARGING_DEVICE_INFORMATION.METER_ALREADY_EXISTS.METER_NUMBER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="meterNumber"
                formControlName="meterNumber"
                data-testId="meter-number" />
              <mat-error appErrorMessages="meterNumber"></mat-error>
            </mat-form-field>
          </div>
        }
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="loadManagement"
          aria-labelledby="loadManagement-radio-group-label"
          class="input-container">
          @for (loadManagement of loadManagementOptions; track loadManagement) {
            <mat-radio-button
              [value]="loadManagement"
              [attr.data-testId]="loadManagement">
              {{
                'CHARGING_DEVICE_INFORMATION.LOAD_MANAGEMENT.' + loadManagement
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="loadManagement"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
