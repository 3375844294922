<div class="document-container">
  <div class="document-details">
    <h2 class="document-name">
      @if (documentName) {
        {{ documentName | translate }}
      } @else {
        {{
          'DOCUMENTS_UPLOAD.DOCUMENT_TYPE.' + documentDetails.typeName
            | translate
        }}
      }

      <ng-template #typeName
        >{{
          'DOCUMENTS_UPLOAD.DOCUMENT_TYPE.' + documentDetails.typeName
            | translate
        }}
      </ng-template>

      @if (documentDetails.required) {
        <span>*</span>
      }
    </h2>

    @if (documentDetails.downloadLink) {
      <a
        class="document-download bold"
        [href]="documentDetails.downloadLink"
        target="_blank"
        download>
        <mat-icon class="material-icons-outlined">file_download</mat-icon>
        {{ 'DOCUMENTS_UPLOAD.DOWNLOAD' | translate }}
      </a>
    }

    <span class="document-description">
      @if (documentDescription) {
        {{ documentDescription | translate }}
      } @else {
        <div
          [innerHTML]="
            'DOCUMENTS_UPLOAD.DOCUMENT_TYPE_DESCRIPTION.' +
              documentDetails.description | translate
          "></div>
      }
    </span>
  </div>

  <div class="upload-section">
    <input
      hidden
      type="file"
      [accept]="acceptedFileTypes"
      (change)="onFileSelected($event)"
      onclick="value=null;"
      #fileUpload />
    <button mat-stroked-button (click)="selectFile(fileUpload)">
      <mat-icon>add</mat-icon>
      {{ 'DOCUMENTS_UPLOAD.SELECT' | translate }}
    </button>
  </div>
</div>

@if (allTouched || documentTouched) {
  <mat-error [appErrorMessages]="documentControlName"> </mat-error>
}

@if (multipleDocumentUpload) {
  @if (documentControl.value?.length) {
    <div class="uploaded-document-container">
      @for (selectedDocument of documentControl.value; track selectedDocument) {
        <div class="uploaded-document">
          <span class="document-name">
            <mat-icon>file_download_done</mat-icon>
            <span>{{ selectedDocument.name }}</span>
          </span>
          <span>{{ selectedDocument.file.size | filesize: locale }}</span>
          <button
            mat-icon-button
            (click)="removeSelectedDocument(selectedDocument)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      }
    </div>
  }
} @else {
  @if (documentControl.value) {
    <div class="uploaded-document-container">
      <div class="uploaded-document">
        <span class="document-name">
          <mat-icon>file_download_done</mat-icon>
          <span>{{ documentControl.value.name }}</span>
        </span>
        <span>{{ documentControl.value.file.size | filesize: locale }}</span>
        <button mat-icon-button (click)="removeSelectedDocument()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  }
}
