import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { filter, map, take } from 'rxjs';
import { BalconyPVInfo } from '@app/models/form-data.interface';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';

@Component({
  standalone: true,
  selector: 'app-balcony-pv-info',
  templateUrl: './balcony-pv-info.component.html',
  imports: [SharedModule, TranslatePipe, RequiredSuffixDirective],
})
export class BalconyPvInfoComponent implements OnInit {
  public form!: FormGroup;
  public allTouched = false;

  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #routeService: RouteService = inject(RouteService);

  public ngOnInit(): void {
    this.#createForm();
    this.#initFormData();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      modulePower: [null, [Validators.required]],
      inverterPower: [null, [Validators.required]],
      freeFeedIn: [null, [Validators.required]],
    });
  }

  #initFormData(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(({ formData }) => formData.balconyPVInfo),
        filter(Boolean),
        take(1)
      )
      .subscribe(balconyPV => this.form.patchValue(balconyPV));
  }

  #updateState(): void {
    const balconyPV = this.form.value;
    this.#appStateService.updateFormData({
      balconyPVInfo: {
        ...balconyPV,
        registrationRequired: this.#isRegistrationRequired(balconyPV),
      },
    });
  }

  #isRegistrationRequired(balconyPV: BalconyPVInfo): boolean {
    return !(
      balconyPV.modulePower &&
      balconyPV.inverterPower &&
      balconyPV.freeFeedIn
    );
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.allTouched = true;
      this.form.markAllAsTouched();
    }
  }
}
