import { SharedModule } from '@app/modules/shared/shared.module';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-selected-pv',
  standalone: true,
  imports: [SharedModule, TranslateModule],
  templateUrl: './selected-pv.component.html',
})
export class SelectedPvComponent {
  public showHints = input<boolean>(false);
}
