<app-summary-section
  heading="OTHER_2_SYSTEM_DETAILS.HEADING"
  [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.SYSTEM_MANUFACTURER"
      [value]="otherSystemDetails.systemManufacturer">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.SYSTEM_MODEL"
      [value]="otherSystemDetails.systemModel">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.SYSTEM_COUNT"
      [value]="otherSystemDetails.systemCount">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.SYSTEM_TYPE"
      [value]="
        'OTHER_2_SYSTEM_DETAILS.SYSTEM_TYPE.' + otherSystemDetails.systemType
          | translate
      ">
    </app-summary-section-field>
    @if (otherSystemDetails.systemType === OTHER_SYSTEM_TYPE.OTHER) {
      <app-summary-section-field
        label="OTHER_2_SYSTEM_DETAILS.OTHER_SYSTEM_TYPE"
        [value]="otherSystemDetails.otherSystemType">
      </app-summary-section-field>
    }
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.POWER"
      [value]="otherSystemDetails.power">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.MAX_STARTING_CURRENT"
      [value]="otherSystemDetails.maxStartingCurrent">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY"
      [value]="otherSystemDetails.maxControllablePowerSupply">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.SEPARATE_METER.TITLE"
      [value]="
        (otherSystemDetails.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.METER_AVAILABLE.TITLE"
      [value]="
        (otherSystemDetails.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>
    @if (otherSystemDetails.meterAvailable) {
      <app-summary-section-field
        label="OTHER_2_SYSTEM_DETAILS.METER_NUMBER"
        [value]="otherSystemDetails.meterNumber">
      </app-summary-section-field>
    }
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.CRITICAL_INFRASTRUCTURE"
      [value]="
        (otherSystemDetails.operationalPurposeOrCriticalInfrastructure
          ? 'COMMON.YES'
          : 'COMMON.NO'
        ) | translate
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_SYSTEM_DETAILS.LOAD_MANAGEMENT.TITLE"
      [value]="
        'OTHER_2_SYSTEM_DETAILS.LOAD_MANAGEMENT.' +
          otherSystemDetails.loadManagement | translate
      ">
    </app-summary-section-field>
  </div>
</app-summary-section>
