import {
  Address,
  BUILDING_TYPE,
  CHANGE_TYPE,
  ChangeOtherDetailsDto,
  CHARGING_DEVICE_TYPE,
  ConstructionElectricity,
  ContactDetails,
  CONTROL_TYPE,
  CONTROLLABLE_POWER,
  ControllableDetailsDto,
  CONTROLLER,
  Document,
  EXISTING_SYSTEM,
  FORM_OF_FEED_IN_SALE,
  FORM_TYPE,
  GAS_OPTION,
  GasBuildingDetails,
  GRID_CONNECTION_CONSUMER_TYPE,
  GRID_CONNECTION_TYPE,
  HEAT_OPTION,
  HeatBuildingDetails,
  LOAD_MANAGEMENT,
  NETWORK_FEE_MODULE,
  NETWORK_AND_SYSTEM_PROTECTION,
  NetworkAndSystemProtectionDetailsDto,
  ON_BEHALF_TYPE,
  OTHER_TYPE,
  OtherSystemDetailsDto,
  PowerChangeDetails,
  PV_INSTALLATION_PLACE,
  PVCustomerFacilityDto,
  PVInverterDto,
  SEWAGE_OPTION,
  SEWAGE_TYPE,
  StorageDetails,
  StorageDetailsDto,
  WATER_OPTION,
  WaterBuildingDetails,
} from '@app/models/registration-form';
import {
  PartialFormData,
  PartialOtherData,
} from '../services/app-state.service';
import {
  COMMISSIONING_FLOW_TYPE,
  ELECTRICITY_SYSTEM_TYPE,
  MainPowerSupplyPowerIncrease,
  MainPowerSupplyType,
  MeasurementDetails,
} from './commissioning-mapped-data.interface';

interface AdditionalAddressData {
  district?: string;
  parcel?: string;
  parcelNumber?: string;
}

export enum FLOW {
  REGISTRATION = 'REGISTRATION',
  COMMISSIONING = 'COMMISSIONING',
}

export enum FLOW_MODULE {
  GAS = 'GAS',
  ELECTRICITY = 'ELECTRICITY',
  WATER = 'WATER',
  HEAT = 'HEAT',
}

export type WATER_AREA = Extract<
  FORM_TYPE,
  FORM_TYPE.WATER | FORM_TYPE.SEWAGE | FORM_TYPE.CONSTRUCTION_WATER
>;

export interface ExtendedAddress extends Address {
  streetNotListed: boolean;
  additionalAddressData?: AdditionalAddressData;
}

export interface DocumentWithFile extends Document {
  file: File;
}

export interface FormContact {
  onBehalf: boolean;
  onBehalfType?: ON_BEHALF_TYPE;
  powerOfAttorney?: boolean;
  powerOfAttorneyDocument?: DocumentWithFile;
  contactForm: ContactDetails;
  differentContactForm?: ContactDetails;
  differentAddress: boolean;
  differentAddressForm?: Address;
}

export interface DocumentDetailsWithFile {
  documents: DocumentWithFile[];
  notes?: string;
}

export interface PvInverter extends PVInverterDto {
  maxApparentPowerSum: number;
  maxControllablePowerSum: number;
}
export interface PvGenerationUnit {
  powerPerModule: number;
  moduleCount: number;
  installationPlace: PV_INSTALLATION_PLACE;
  powerFlowMonitoring?: boolean;
  powerFlowMonitoringManufacturer?: string;
  powerFlowMonitoringType?: string;
  powerFlowMonitoringPower?: number;
  networkAndSystemProtectionDetailsLocation?: NETWORK_AND_SYSTEM_PROTECTION;
  networkAndSystemProtectionDetailsManufacturer?: string;
  networkAndSystemProtectionDetailsType?: string;
}

export interface BalconyPVInfo {
  modulePower: boolean;
  inverterPower: boolean;
  freeFeedIn: boolean;
  registrationRequired: boolean;
}

export interface ChargingDeviceSystemInformation {
  plannedCommissioningDate?: string;
  controllable: boolean;
}

export interface ChargingDeviceControllableDetails {
  totalMinimumPower: number;
  compliant: boolean;
  controller: CONTROLLER;
  existingSystem: EXISTING_SYSTEM;
  controlType: CONTROL_TYPE;
  networkFeeModule: NETWORK_FEE_MODULE;
  controllerName?: string; // Required, if controller = COMPETITIVE_METERING_POINT_OPERATOR
}

export interface ChargingDeviceInformation {
  systemManufacturer?: string;
  systemType?: string;
  systemCount: number;
  type: CHARGING_DEVICE_TYPE;
  public: boolean;
  specialRights: boolean;
  chargingPointACCount: number;
  chargingPointDCCount: number;
  maxGridPower: number;
  maxGridFeedInPower: number;
  systemPower: number;
  separateMeter: boolean;
  meterAvailable: boolean;
  meterNumber?: string; // Required, if meterAvailable = true
  loadManagement: LOAD_MANAGEMENT;
}

type GridConnectionConsumerType = Partial<{
  [K in GRID_CONNECTION_CONSUMER_TYPE]: boolean;
}>;

export interface GridConnectionConsumer {
  type: GridConnectionConsumerType;
  connectionFuse: number;
  systemPower: number;
  maxConcurrentPower: number;
  additionalConsumers: string;
}

export interface FormNewGridConnectionDetails {
  buildingType: BUILDING_TYPE;
  basementAvailable: boolean;
  externalConnection: boolean;
  flatCount?: number;
  area?: number;
}

export interface FormChangeGridConnectionDetails {
  changeType: CHANGE_TYPE;
  totalPowerChanged: boolean;
  ditchLength: number;
}

export interface HeatPump {
  systemManufacturer?: string;
  systemType?: string;
  systemPower: number;
  meterRequired: boolean;
  meterNumber?: string;
  connectionPowerIncrease: boolean;
  currentPowerDemand?: number;
  plannedPowerDemand?: number;
  controllablePower: CONTROLLABLE_POWER;
  notes?: string;
}

export interface GasOptions {
  gasOption: GAS_OPTION;
  changePower?: boolean;
  relocate?: boolean;
  currentPowerDemand?: number;
  plannedPowerDemand?: number;
  ditchLength?: number;
  partialDeconstructionDesired?: boolean;
  meterNumber?: string;
}

export interface GasPowerRequirement {
  powerDemand: number;
  notes?: string;
}
export interface WaterRequirementDetails {
  numberOfHousingUnits: number;
  numberOfResidents?: number;
  numberOfFloors: number;
  extractionPointHeight: number;
  flowRate?: number;
  additionalConsumers?: string;
}

export interface ConstructionWaterRequirements {
  flowRate?: number;
  convertedSpace: number;
  desiredInstallationDate: Date;
  desiredDeconstructionDate?: Date;
  notes?: string;
}

export interface WaterOptions {
  waterOption: WATER_OPTION;
  changeFlowRate?: boolean;
  relocate?: boolean;
  currentFlowRate?: number;
  plannedFlowRate?: number;
  additionalDemand?: string;
  ditchLength?: number;
  permanentDeconstruction?: boolean;
  meterNumber?: string;
  deconstructionUntil?: Date;
}

export interface SewageOptions {
  sewageOption: SEWAGE_OPTION;
  permanentDeconstruction?: boolean;
  meterNumber?: string;
  deconstructionUntil?: Date;
}

export interface HeatOptions {
  heatOption: HEAT_OPTION;
  changeHeatPower?: boolean;
  changeOther?: boolean;
  currentHeatPower?: number;
  plannedHeatPower?: number;
  additionalHeatDemand?: string;
  otherChanges?: string;
  permanentDeconstruction?: boolean;
  meterNumber?: string;
  deconstructionUntil?: Date;
}

export interface HeatRequirementDetails {
  heatOutputDemand: number;
  otherConsumers?: string;
  otherConsumersPower?: number;
  notes?: string;
}

export interface CommissioningElectricityBuildingDetails {
  buildingType: BUILDING_TYPE;
  flatCount?: number;
  area?: number;
}

export interface CommissioningElectricityInformation {
  systemPower: number;
  connectionFuse: number;
  constructionElectricity: boolean;
}

export interface SystemDetails {
  selected: boolean;
  systemPower?: number;
  systemCount?: number;
  systemType?: string;
  notes?: string;
}

export interface CommissioningElectricitySystems {
  [ELECTRICITY_SYSTEM_TYPE.PV]: SystemDetails;
  [ELECTRICITY_SYSTEM_TYPE.STORAGE]: SystemDetails;
  [ELECTRICITY_SYSTEM_TYPE.CHARGING_DEVICE]: SystemDetails;
  [ELECTRICITY_SYSTEM_TYPE.HEAT_PUMP]: SystemDetails;
  [ELECTRICITY_SYSTEM_TYPE.OTHER]: SystemDetails;
  meterChanges: boolean;
}

export interface CommissioningElectricitySimpleMeasurementDetails {
  meterNumbers: string[];
}

export interface CommissioningElectricityContact {
  contactDetails: ContactDetails;
  differentAddress: boolean;
  gridOperator: string;
  idNumber: string;
  electricianName: string;
  electricianEmail: string;
  phone: string;
  location: string;
  date: Date;
  differentRequesterAddress?: Address;
}

export type OtherCustomerFacility = {
  controllable: boolean;
  plannedCommissioningDate?: string;
};

export type StorageCustomerFacility = {
  controllable: boolean;
  plannedCommissioningDate?: string;
  formOfFeedInSale: FORM_OF_FEED_IN_SALE;
};

export interface FormDataObject {
  address?: ExtendedAddress;
  selectedModule?: FLOW_MODULE;
  selectedFlow?: FLOW;
  documentDetails?: DocumentDetailsWithFile;
  contact?: FormContact;
  balconyPVInfo?: BalconyPVInfo;
  formType?: FORM_TYPE | null;
  isBalconyPV?: boolean;
  pvCustomerFacility?: PVCustomerFacilityDto;
  pvInverter?: PvInverter;
  pvGenerationUnit?: PvGenerationUnit;
  storageDetails?: StorageDetails;
  storageCustomerFacility?: StorageCustomerFacility;
  storageDetailsDto?: StorageDetailsDto;
  storageControllableDetails?: ControllableDetailsDto;
  storageNetworkAndSystemProtectionDetails?: NetworkAndSystemProtectionDetailsDto;
  chargingDeviceSystemInformation?: ChargingDeviceSystemInformation;
  chargingDeviceControllableDetails?: ChargingDeviceControllableDetails;
  chargingDeviceInformation?: ChargingDeviceInformation;
  gridConnectionType?: GRID_CONNECTION_TYPE;
  gridConnectionConsumer?: GridConnectionConsumer;
  powerChangeDetails?: PowerChangeDetails;
  newGridConnectionDetails?: FormNewGridConnectionDetails;
  changeGridConnectionDetails?: FormChangeGridConnectionDetails;
  constructionElectricity?: ConstructionElectricity;
  heatPump?: HeatPump;
  otherType?: OTHER_TYPE;
  otherCustomerFacility?: OtherCustomerFacility;
  otherControllableDetails?: ControllableDetailsDto;
  otherSystemDetails?: OtherSystemDetailsDto;
  otherDetails?: Omit<ChangeOtherDetailsDto, 'type'>;
  gasOptions?: GasOptions;
  gasBuildingDetails?: GasBuildingDetails;
  gasPowerRequirement?: GasPowerRequirement;
  sewageOptions?: SewageOptions;
  sewageType?: SEWAGE_TYPE;
  waterOptions?: WaterOptions;
  waterArea?: WATER_AREA;
  constructionWaterRequirements?: ConstructionWaterRequirements;
  waterBuildingDetails?: WaterBuildingDetails;
  waterRequirementDetails?: WaterRequirementDetails;
  heatOptions?: HeatOptions;
  heatBuildingDetails?: HeatBuildingDetails;
  heatRequirementDetails?: HeatRequirementDetails;
  commissioningFlowType?: COMMISSIONING_FLOW_TYPE;
  mainPowerSupplyType?: MainPowerSupplyType;
  commissioningElectricityBuildingDetails?: CommissioningElectricityBuildingDetails;
  commissioningElectricityInformation?: CommissioningElectricityInformation;
  mainPowerSupplyPowerIncreaseDetails?: MainPowerSupplyPowerIncrease;
  commissioningElectricitySystems?: CommissioningElectricitySystems;
  commissioningElectricitySimpleMeasurementDetails?: CommissioningElectricitySimpleMeasurementDetails;
  commissioningElectricityMeasurementDetails?: MeasurementDetails[];
  commissioningElectricityContact?: CommissioningElectricityContact;
}

export interface OtherData {
  productSelectionVisited?: boolean;
  responsibilityCheckFail?: boolean;
}

export interface AppState {
  formData: PartialFormData;
  otherData: PartialOtherData;
}
