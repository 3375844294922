import { NgModule } from '@angular/core';
import { AddressFormComponent } from './address-form/address-form.component';
import { ContactComponent } from './contact/contact.component';
import { DocumentUploadSectionComponent } from './documents-upload/document-upload-section/document-upload-section.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { ErrorComponent } from './error/error.component';
import { MainDomainPageComponent } from './main-domain-page/main-domain-page.component';
import { ResponsibilityCheckFailComponent } from './responsibility-check-fail/responsibility-check-fail.component';
import { SuccessComponent } from './success/success.component';
import { SummaryComponent } from './summary/summary.component';
import { SharedModule } from '../shared/shared.module';
import { WebpackTranslateLoader } from '@app/app-initialization/webpack-translation-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModuleSelectionComponent } from './module-selection/module-selection.component';
import { HeatModule } from '../heat/heat.module';
import { IsModuleOptionEnabledPipe } from '@app/shared/pipes/is-module-option-enabled.pipe';
import { FlowSelectionComponent } from './flow-selection/flow-selection.component';
import { MainPowerSupplySummaryComponent } from './summary/commissioning/main-power-supply-summary/main-power-supply-summary.component';
import { RegistrationContactDataComponent } from './summary/registration/registration-contact-data/registration-contact-data.component';
import { CommissioningContactDataComponent } from './summary/commissioning/commissioning-contact-data/commissioning-contact-data.component';
import { ContactDetailsComponent } from './summary/common/contact-details/contact-details.component';
import { AddressComponent } from './summary/common/address/address.component';
import { CustomerFacilitySummaryComponent } from './summary/commissioning/customer-facility-summary/customer-facility-summary.component';
import { SimpleMeasurementDetailsSummaryComponent } from '@app/modules/common-steps/summary/commissioning/customer-facility-summary/simple-measurement-details-summary/simple-measurement-details-summary.component';
import { ComplexMeasurementDetailsSummaryComponent } from '@app/modules/common-steps/summary/commissioning/customer-facility-summary/complex-measurement-details-summary/complex-measurement-details-summary.component';
import { ComplexMeasurementDetailSummaryComponent } from './summary/commissioning/customer-facility-summary/complex-measurement-details-summary/complex-measurement-detail-summary/complex-measurement-detail-summary.component';
import { MapWithPrefixPipe } from '@app/shared/pipes/map-with-prefix.pipe';
import { MapTranslatePipe } from '@app/shared/pipes/map-translate.pipe';
import { BooleanTranslateKeyPipe } from '@app/shared/pipes/boolean-translate-key.pipe';
import { HasResponsibilityCheckPipe } from './pipes/has-responsibility-check.pipe';
import { ObjectLookupPipe } from '@app/shared/pipes/object-lookup.pipe';
import { ConstructionElectricityDetailsSummaryComponent } from './summary/registration/construction-electricity-details-summary/construction-electricity-details-summary.component';
import { NextButtonDisabledAddressPipe } from './address-form/next-button-disabled-address.pipe';
import { ConstructionWaterRequirementsSummaryComponent } from './summary/registration/construction-water-requirements-summary/construction-water-requirements-summary.component';
import { NgxTurnstileComponent } from '@app/modules/shared/ngx-turnstile/ngx-turnstile.component';
import { NewGridConnectionSummaryComponent } from '@app/modules/common-steps/summary/commissioning/main-power-supply-summary/new-grid-connection-summary/new-grid-connection-summary.component';
import { PowerIncreaseSummaryComponent } from '@app/modules/common-steps/summary/commissioning/main-power-supply-summary/power-increase-summary/power-increase-summary.component';
import { ChipComponent } from '@app/modules/common-steps/summary/chip/chip.component';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { FileSizePipe } from '@app/modules/common-steps/pipes/filesize.pipe';
import { DecimalPipe } from '@angular/common';
import { PvSummaryComponent } from './summary/components/pv-summary/pv-summary.component';
import { OtherTypeSummaryComponent } from '@app/modules/common-steps/summary/registration/other-2/other-type-summary/other-type-summary.component';
import { OtherCustomerFacilitySummaryComponent } from '@app/modules/common-steps/summary/registration/other-2/other-customer-facility-summary/other-customer-facility-summary.component';
import { OtherControllableDetailsSummaryComponent } from '@app/modules/common-steps/summary/registration/other-2/other-controllable-details-summary/other-controllable-details-summary.component';
import { OtherSystemDetailsSummaryComponent } from '@app/modules/common-steps/summary/registration/other-2/other-system-details-summary/other-system-details-summary.component';
import { OtherDetailsSummaryComponent } from '@app/modules/common-steps/summary/registration/other-2/other-details-summary/other-details-summary.component';
import { StorageCustomerFacilitySummaryComponent } from '@app/modules/common-steps/summary/registration/storage-2/storage-customer-facility-summary/storage-customer-facility-summary.component';
import { StorageControllableSystemSummaryComponent } from '@app/modules/common-steps/summary/registration/storage-2/storage-controllable-system-summary/storage-controllable-system-summary.component';
import { StorageDetailsSummaryComponent } from '@app/modules/common-steps/summary/registration/storage-2/storage-details-summary/storage-details-summary.component';
import { ChargingDeviceSystemInformationSummaryComponent } from './summary/registration/charging-device-2/charging-device-system-information-summary/charging-device-system-information-summary.component';
import { ChargingDeviceInformationSummaryComponent } from './summary/registration/charging-device-2/charging-device-information-summary/charging-device-information-summary.component';
import { ChargingDeviceControllableSystemSummaryComponent } from './summary/registration/charging-device-2/charging-device-controllable-system-summary/charging-device-controllable-system-summary.component';

@NgModule({
  declarations: [
    AddressFormComponent,
    ContactComponent,
    DocumentsUploadComponent,
    DocumentUploadSectionComponent,
    ErrorComponent,
    MainDomainPageComponent,
    ResponsibilityCheckFailComponent,
    SuccessComponent,
    SummaryComponent,
    ModuleSelectionComponent,
    FlowSelectionComponent,
    MainPowerSupplySummaryComponent,
    RegistrationContactDataComponent,
    CommissioningContactDataComponent,
    ContactDetailsComponent,
    AddressComponent,
    CustomerFacilitySummaryComponent,
    SimpleMeasurementDetailsSummaryComponent,
    ComplexMeasurementDetailsSummaryComponent,
    ComplexMeasurementDetailSummaryComponent,
    ConstructionElectricityDetailsSummaryComponent,
    NextButtonDisabledAddressPipe,
    ConstructionWaterRequirementsSummaryComponent,
    NewGridConnectionSummaryComponent,
    PowerIncreaseSummaryComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    HeatModule,
    IsModuleOptionEnabledPipe,
    MapWithPrefixPipe,
    MapTranslatePipe,
    BooleanTranslateKeyPipe,
    HasResponsibilityCheckPipe,
    ObjectLookupPipe,
    NgxTurnstileComponent,
    ChipComponent,
    RequiredSuffixDirective,
    FileSizePipe,
    PvSummaryComponent,
    StorageCustomerFacilitySummaryComponent,
    StorageControllableSystemSummaryComponent,
    StorageDetailsSummaryComponent,
    ChargingDeviceSystemInformationSummaryComponent,
    ChargingDeviceControllableSystemSummaryComponent,
    ChargingDeviceInformationSummaryComponent,
    OtherTypeSummaryComponent,
    OtherCustomerFacilitySummaryComponent,
    OtherControllableDetailsSummaryComponent,
    OtherSystemDetailsSummaryComponent,
    OtherDetailsSummaryComponent,
  ],
  exports: [DocumentUploadSectionComponent],
  providers: [DecimalPipe],
})
export class CommonStepsModule {}
