<app-summary-section heading="OTHER_2_DETAILS.HEADING" [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="OTHER_2_DETAILS.CHANGE_SYSTEM"
      [value]="
        'OTHER_2_DETAILS.CHANGE_SYSTEM.' + otherDetails.changeSystem | translate
      ">
    </app-summary-section-field>
    @if (otherDetails.changeSystem === CHANGE_SYSTEM_TYPE.OTHER) {
      <app-summary-section-field
        label="OTHER_2_DETAILS.OTHER_CHANGE_SYSTEM"
        [value]="otherDetails.otherChangeSystem">
      </app-summary-section-field>
    }
    <app-summary-section-field
      label="OTHER_2_DETAILS.CONTROLLABLE"
      [value]="
        (otherDetails.controllable ? 'COMMON.YES' : 'COMMON.NO') | translate
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_DETAILS.OTHER_CHANGE_TYPE"
      [value]="
        'OTHER_2_DETAILS.OTHER_CHANGE_TYPE.' + otherDetails.changeType
          | translate
      ">
    </app-summary-section-field>
    @if (otherDetails.changeType === OTHER_CHANGE_TYPE.POWER_INCREASE) {
      <app-summary-section-field
        label="OTHER_2_DETAILS.POWER"
        [value]="otherDetails.power">
      </app-summary-section-field>
      <app-summary-section-field
        label="OTHER_2_DETAILS.PLANNED_POWER"
        [value]="otherDetails.plannedPower">
      </app-summary-section-field>
    }
    <app-summary-section-field
      label="OTHER_2_DETAILS.DESCRIPTION.TITLE"
      [value]="otherDetails.description">
    </app-summary-section-field>
  </div>
</app-summary-section>
