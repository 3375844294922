<app-summary-section
  heading="OTHER_2_CUSTOMER_FACILITY.HEADING"
  [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="OTHER_2_CUSTOMER_FACILITY.PLANNED_COMMISSIONING_DATE.TITLE"
      [value]="
        !!otherCustomerFacility.plannedCommissioningDate
          ? (otherCustomerFacility.plannedCommissioningDate
              | date: 'shortDate' : '' : currentLanguageCulture)!
          : undefined
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="OTHER_2_CUSTOMER_FACILITY.CONTROLLABLE.TITLE"
      [value]="
        (otherCustomerFacility.controllable ? 'COMMON.YES' : 'COMMON.NO')
          | translate
      ">
    </app-summary-section-field>
  </div>
</app-summary-section>
