<app-summary-section heading="STORAGE_DETAILS.HEADING" [stepRoute]="route">
  <div class="section-fields-container">
    <app-summary-section-field
      label="STORAGE_DETAILS.OPERATING_MODE.TITLE"
      [value]="
        'STORAGE_DETAILS.OPERATING_MODE.' + storageDetails.operatingMode
          | translate
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="STORAGE_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY"
      [value]="storageDetails.maxControllablePowerSupply">
    </app-summary-section-field>
    <app-summary-section-field
      label="STORAGE_DETAILS.LOAD_MANAGEMENT.TITLE"
      [value]="
        'STORAGE_DETAILS.LOAD_MANAGEMENT.' + storageDetails.loadManagement
          | translate
      ">
    </app-summary-section-field>
    <app-summary-section-field
      label="STORAGE_DETAILS.CAPACITY"
      [value]="storageDetails.capacity">
    </app-summary-section-field>
    <app-summary-section-field
      label="STORAGE_DETAILS.UNIT_COUPLING.TITLE"
      [value]="
        'STORAGE_DETAILS.UNIT_COUPLING.' + storageDetails.unitCoupling
          | translate
      ">
    </app-summary-section-field>
    @if (storageDetails.unitCoupling === UNIT_COUPLING.OWN_INVERTER) {
      <app-summary-section-field
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_MANUFACTURER"
        [value]="storageDetails.systemManufacturer">
      </app-summary-section-field
      ><app-summary-section-field
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_TYPE"
        [value]="storageDetails.systemType">
      </app-summary-section-field
      ><app-summary-section-field
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_COUNT"
        [value]="storageDetails.systemCount">
      </app-summary-section-field
      ><app-summary-section-field
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER"
        [value]="storageDetails.maxApparentPower">
      </app-summary-section-field
      ><app-summary-section-field
        label="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER_SUM"
        [value]="
          (storageDetails.systemCount || 0) *
          (storageDetails.maxApparentPower || 0)
        ">
      </app-summary-section-field>
      <app-summary-section-field
        label="STORAGE_DETAILS.SEPARATE_METER.TITLE"
        [value]="
          (storageDetails.separateMeter ? 'COMMON.YES' : 'COMMON.NO')
            | translate
        ">
      </app-summary-section-field>
      <app-summary-section-field
        label="STORAGE_DETAILS.METER_AVAILABLE.TITLE"
        [value]="
          (storageDetails.meterAvailable ? 'COMMON.YES' : 'COMMON.NO')
            | translate
        ">
      </app-summary-section-field>
      @if (storageDetails.meterAvailable) {
        <app-summary-section-field
          label="STORAGE_DETAILS.METER_NUMBER"
          [value]="storageDetails.meterNumber">
        </app-summary-section-field>
      }
    }
  </div>
</app-summary-section>
