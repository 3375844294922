import { Component, Input } from '@angular/core';
import { OTHER_TYPE } from '@app/models/registration-form';
import { ROUTES } from '@app/models/routes.enum';
import { SharedModule } from '@app/modules/shared/shared.module';
import { ChipComponent } from '@app/modules/common-steps/summary/chip/chip.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-other-type-summary',
  standalone: true,
  imports: [SharedModule, ChipComponent, TranslatePipe],
  templateUrl: './other-type-summary.component.html',
})
export class OtherTypeSummaryComponent {
  @Input({ required: true })
  public otherType!: OTHER_TYPE;
  public readonly route = ROUTES.OTHER_2_TYPE;
}
