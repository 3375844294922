import { Component, Input } from '@angular/core';
import { ChargingDeviceControllableDetails } from '@app/models/form-data.interface';
import { CONTROLLER } from '@app/models/registration-form';
import { ROUTES } from '@app/models/routes.enum';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
@Component({
  selector: 'app-charging-device-controllable-system-summary',
  standalone: true,
  imports: [SharedModule, TranslatePipe],
  templateUrl: './charging-device-controllable-system-summary.component.html',
})
export class ChargingDeviceControllableSystemSummaryComponent {
  @Input({ required: true })
  public controllableDetails!: ChargingDeviceControllableDetails;
  public readonly route = ROUTES.CHARGING_DEVICE_CONTROLLABLE_SYSTEM;
  public readonly CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR =
    CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR;
}
