<app-form-card
  titleIcon="electric_bolt"
  cardTitle="STORAGE_DETAILS.HEADING"
  data-testId="storage-details-heading">
  <p>
    {{ 'STORAGE_DETAILS.DESCRIPTION' | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_DETAILS.OPERATING_MODE.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_DETAILS.OPERATING_MODE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="operatingMode"
          aria-labelledby="operatingMode-radio-group-label"
          class="input-container">
          @for (operatingMode of STORAGE_OPERATING_MODE; track operatingMode) {
            <mat-radio-button
              [value]="operatingMode"
              [attr.data-testId]="operatingMode">
              {{
                'STORAGE_DETAILS.OPERATING_MODE.' + operatingMode | translate
              }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="operatingMode" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="full-width-flex-element">
            <mat-label
              >{{ 'STORAGE_DETAILS.MAX_CONTROLLABLE_POWER_SUPPLY' | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              name="maxControllablePowerSupply"
              formControlName="maxControllablePowerSupply"
              min="0"
              data-testId="max-controllable-power-supply" />
            <mat-error
              appErrorMessages="maxControllablePowerSupply"></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_DETAILS.LOAD_MANAGEMENT.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_DETAILS.LOAD_MANAGEMENT.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="loadManagement"
          aria-labelledby="loadManagement-radio-group-label"
          class="input-container">
          @for (loadManagement of LOAD_MANAGEMENT; track loadManagement) {
            <mat-radio-button
              [value]="loadManagement"
              [attr.data-testId]="loadManagement">
              {{
                'STORAGE_DETAILS.LOAD_MANAGEMENT.' + loadManagement | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="loadManagement"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_DETAILS.CAPACITY.TITLE' | translate
          }}</span>
          <app-info-tooltip tooltipText="STORAGE_DETAILS.CAPACITY.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="full-width-flex-element">
            <mat-label>{{ 'STORAGE_DETAILS.CAPACITY' | translate }} </mat-label>
            <input
              matInput
              type="number"
              name="capacity"
              formControlName="capacity"
              min="0"
              data-testId="capacity" />
            <mat-error appErrorMessages="capacity"></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_DETAILS.UNIT_COUPLING.TITLE' | translate
          }}</span>
          <app-info-tooltip tooltipText="STORAGE_DETAILS.UNIT_COUPLING.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="unitCoupling"
          aria-labelledby="unitCoupling-radio-group-label"
          class="input-container">
          @for (unitCoupling of UNIT_COUPLING; track unitCoupling) {
            <mat-radio-button
              [value]="unitCoupling"
              [attr.data-testId]="unitCoupling">
              {{ 'STORAGE_DETAILS.UNIT_COUPLING.' + unitCoupling | translate }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="unitCoupling" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      @if (form.get('unitCoupling')?.value === UNIT_COUPLING_OWN_INVERTER) {
        <div class="two-column-layout-item">
          <h2 appRequiredSuffix>
            {{ 'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.TITLE' | translate }}
          </h2>
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_MANUFACTURER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="systemManufacturer"
                formControlName="systemManufacturer"
                data-testId="system-manufacturer" />
              <mat-error appErrorMessages="systemManufacturer"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_MANUFACTURER.TOOLTIP">
            </app-info-tooltip>
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_TYPE'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="systemType"
                formControlName="systemType"
                data-testId="system-type" />
              <mat-error appErrorMessages="systemType"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_TYPE.TOOLTIP">
            </app-info-tooltip>
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_COUNT'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                type="number"
                name="systemCount"
                formControlName="systemCount"
                min="0"
                data-testId="system-count" />
              <mat-error appErrorMessages="systemCount"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.SYSTEM_COUNT.TOOLTIP">
            </app-info-tooltip>
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                type="number"
                name="maxApparentPower"
                formControlName="maxApparentPower"
                min="0"
                data-testId="max-apparent-power" />
              <mat-error appErrorMessages="maxApparentPower"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER.TOOLTIP">
            </app-info-tooltip>
          </div>

          <div>
            {{
              'STORAGE_DETAILS.UNIT_COUPLING.DETAILS.MAX_APPARENT_POWER_SUM'
                | translate
            }}:
            <span data-testId="max-apparent-power-sum">{{
              maxApparentPowerSum | number: '1.2-2' : locale
            }}</span>
          </div>

          <div class="space"></div>
        </div>
      }
    </div>

    @if (form.get('unitCoupling')?.value === UNIT_COUPLING_OWN_INVERTER) {
      <div class="space"></div>

      <div class="two-column-layout-container">
        <div class="two-column-layout-item">
          <h2 class="tooltip-heading-label">
            <span appRequiredSuffix>{{
              'STORAGE_DETAILS.SEPARATE_METER.TITLE' | translate
            }}</span>
            <app-info-tooltip
              tooltipText="STORAGE_DETAILS.SEPARATE_METER.TOOLTIP">
            </app-info-tooltip>
          </h2>
          <mat-radio-group
            color="primary"
            formControlName="separateMeter"
            aria-labelledby="separateMeter-radio-group-label"
            class="input-container">
            <mat-radio-button [value]="true" data-testId="separate-meter-yes">
              {{ 'COMMON.YES' | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false" data-testId="separate-meter-no">
              {{ 'COMMON.NO' | translate }}
            </mat-radio-button>
            <mat-error
              appErrorMessages="separateMeter"
              [hideError]="!allTouched">
            </mat-error>
          </mat-radio-group>
        </div>

        <div class="two-column-layout-item">
          <h2 class="tooltip-heading-label">
            <span appRequiredSuffix>{{
              'STORAGE_DETAILS.METER_AVAILABLE.TITLE' | translate
            }}</span>
            <app-info-tooltip
              tooltipText="STORAGE_DETAILS.METER_AVAILABLE.TOOLTIP">
            </app-info-tooltip>
          </h2>
          <mat-radio-group
            color="primary"
            formControlName="meterAvailable"
            aria-labelledby="meterAvailable-radio-group-label"
            class="input-container">
            <mat-radio-button [value]="true" data-testId="meter-available-yes">
              {{ 'COMMON.YES' | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false" data-testId="meter-available-no">
              {{ 'COMMON.NO' | translate }}
            </mat-radio-button>
            <mat-error
              appErrorMessages="meterAvailable"
              [hideError]="!allTouched">
            </mat-error>
          </mat-radio-group>
          @if (form.get('meterAvailable')?.value === true) {
            <div class="input-container">
              <mat-form-field
                appearance="outline"
                class="full-width-flex-element">
                <mat-label
                  >{{ 'STORAGE_DETAILS.METER_NUMBER' | translate }}
                </mat-label>
                <input
                  matInput
                  name="meterNumber"
                  formControlName="meterNumber"
                  data-testId="meter-number" />
                <mat-error appErrorMessages="meterNumber"></mat-error>
              </mat-form-field>
            </div>
          }
        </div>
      </div>
    }
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
