import { Component, input, Input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
  @Input() warning: boolean = false;
  _showOutline: boolean | undefined;

  @Input()
  set showOutline(value: boolean | undefined) {
    this._showOutline = value;
  }

  public showIcon: InputSignal<boolean> = input(true);

  get showOutline(): boolean {
    return this._showOutline ?? this.warning;
  }
}
