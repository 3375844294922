<app-form-card
  titleIcon="electric_bolt"
  cardTitle="PV_GENERATION_UNIT_INFORMATION.HEADING"
  description="PV_GENERATION_UNIT_INFORMATION.DESCRIPTION"
  dataTestId="pv-generation-unit-heading">
  <div class="space"></div>

  <form [formGroup]="pvGenerationUnitForm">
    <div class="two-column-layout-container">
      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{ 'PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_DATA' | translate }}
          </label>
        </h2>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>
              {{
                'PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP'
                  | translate
              }}
            </mat-label>
            <input
              matInput
              type="number"
              min="0"
              [attr.data-testid]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
              [name]="GENERATION_UNIT_FORM.POWER_PER_MODULE"
              [formControlName]="GENERATION_UNIT_FORM.POWER_PER_MODULE" />
            <mat-error
              [appErrorMessages]="GENERATION_UNIT_FORM.POWER_PER_MODULE">
            </mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP_TOOLTIP">
          </app-info-tooltip>
        </div>

        <div class="input-field-with-postfix-container">
          <mat-form-field appearance="outline" class="input-field-with-postfix">
            <mat-label>
              {{ 'PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT' | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              min="0"
              [attr.data-testid]="GENERATION_UNIT_FORM.MODULE_COUNT"
              [name]="GENERATION_UNIT_FORM.MODULE_COUNT"
              [formControlName]="GENERATION_UNIT_FORM.MODULE_COUNT" />
            <mat-error
              [appErrorMessages]="
                GENERATION_UNIT_FORM.MODULE_COUNT
              "></mat-error>
          </mat-form-field>
          <app-info-tooltip
            class="input-field-postfix"
            tooltipText="PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT_TOOLTIP">
          </app-info-tooltip>
        </div>
        <div class="space"></div>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label class="label-required">
            {{
              'PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE' | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          [formControlName]="GENERATION_UNIT_FORM.INSTALLATION_PLACE"
          class="input-container">
          @for (option of installationPlaceOptions; track option) {
            <mat-radio-button
              color="primary"
              [value]="option"
              [attr.data-testid]="option">
              {{ 'PV_GENERATION_UNIT_INFORMATION.' + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="GENERATION_UNIT_FORM.INSTALLATION_PLACE"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>
            {{
              'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING' | translate
            }}
          </label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          [formControlName]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING"
          class="input-container">
          <mat-radio-button
            color="primary"
            [value]="true"
            [attr.data-testid]="
              GENERATION_UNIT_FORM.POWER_FLOW_MONITORING + '-true'
            "
            >{{ 'COMMON.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button
            color="primary"
            [value]="false"
            [attr.data-testid]="
              GENERATION_UNIT_FORM.POWER_FLOW_MONITORING + '-false'
            ">
            {{ 'COMMON.NO' | translate }}
          </mat-radio-button>
          <mat-error
            [appErrorMessages]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>

        @if (
          pvGenerationUnitForm.get(GENERATION_UNIT_FORM.POWER_FLOW_MONITORING)
            ?.value === true
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>
                {{
                  'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER
                " />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_MANUFACTURER
                "></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER_TOOLTIP">
            </app-info-tooltip>
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>
                {{
                  'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE
                " />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_TYPE
                "></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_TOOLTIP">
            </app-info-tooltip>
          </div>

          <h2 class="tooltip-heading-label">
            <label class="label-required:">
              {{
                'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_POWER_HEADLINE'
                  | translate
              }}
            </label>
          </h2>
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>
                {{
                  'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                type="number"
                min="0"
                [name]="GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER"
                [formControlName]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER
                " />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.POWER_FLOW_MONITORING_POWER
                ">
              </mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER_TOOLTIP">
            </app-info-tooltip>
          </div>
        }
      </section>

      <section class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <label>{{
            'PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS'
              | translate
          }}</label>
          <app-info-tooltip
            tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          [formControlName]="
            GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
          "
          class="input-container">
          @for (option of networksSystemProtection; track option) {
            <mat-radio-button
              [value]="option"
              [attr.data-testid]="
                'network-and-system-protection-details-' + $index
              ">
              {{ 'PV_GENERATION_UNIT_INFORMATION.' + option | translate }}
            </mat-radio-button>
          }
          <mat-error
            [appErrorMessages]="
              GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
            "
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>

        @if (
          pvGenerationUnitForm.get(
            GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_LOCATION
          )?.value === 'METER_LOCATION'
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>
                {{
                  'PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                [name]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                "
                [formControlName]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                " />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER
                "></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_MANUFACTURER_TOOLTIP">
            </app-info-tooltip>
          </div>

          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label>
                {{
                  'PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                [name]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                "
                [formControlName]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                " />
              <mat-error
                [appErrorMessages]="
                  GENERATION_UNIT_FORM.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE
                "></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE_TOOLTIP">
            </app-info-tooltip>
          </div>
        }
      </section>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: pvGenerationUnitForm.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
