<app-form-card
  titleIcon="electric_bolt"
  cardTitle="STORAGE_CONTROLLABLE_DETAILS.HEADING"
  dataTestId="storage-controllable-system-heading">
  <p>
    {{ 'STORAGE_CONTROLLABLE_DETAILS.DESCRIPTION' | translate }}
  </p>

  <div class="space"></div>

  <form [formGroup]="form">
    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <div class="input-container">
          <mat-form-field appearance="outline" class="full-width-flex-element">
            <mat-label
              >{{
                'STORAGE_CONTROLLABLE_DETAILS.TOTAL_MINIMUM_POWER' | translate
              }}
            </mat-label>
            <input
              matInput
              type="number"
              name="totalMinimumPower"
              formControlName="totalMinimumPower"
              min="0"
              data-testId="total-minimum-power" />
            <mat-error appErrorMessages="totalMinimumPower"></mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="compliant"
          aria-labelledby="compliant-radio-group-label"
          class="input-container">
          <mat-radio-button [value]="true" data-testid="compliant-yes">
            {{ 'STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.YES' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" data-testid="compliant-no">
            {{ 'STORAGE_CONTROLLABLE_DETAILS.COMPLIANT.NO' | translate }}
          </mat-radio-button>
          <mat-error appErrorMessages="compliant" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.CONTROLLER.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.CONTROLLER.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controller"
          aria-labelledby="controller-radio-group-label"
          class="input-container">
          @for (controller of CONTROLLER; track controller) {
            <mat-radio-button
              [value]="controller"
              [attr.data-testId]="controller">
              {{
                'STORAGE_CONTROLLABLE_DETAILS.CONTROLLER.' + controller
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="controller" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
        @if (
          form.get('controller')?.value ===
          CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR
        ) {
          <div class="input-field-with-postfix-container">
            <mat-form-field
              appearance="outline"
              class="input-field-with-postfix">
              <mat-label
                >{{
                  'STORAGE_CONTROLLABLE_DETAILS.CONTROLLER_NAME' | translate
                }}
              </mat-label>
              <input
                matInput
                name="controllerName"
                formControlName="controllerName"
                data-testId="controller-name" />
              <mat-error appErrorMessages="controllerName"></mat-error>
            </mat-form-field>
            <app-info-tooltip
              class="input-field-postfix"
              tooltipText="STORAGE_CONTROLLABLE_DETAILS.CONTROLLER_NAME.TOOLTIP">
            </app-info-tooltip>
          </div>
        }
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.TITLE'
              | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="networkAndSystemProtection_location"
          aria-labelledby="networkAndSystemProtection_location-radio-group-label"
          class="input-container">
          @for (
            networkAndSystemProtectionType of NETWORK_AND_SYSTEM_PROTECTION;
            track networkAndSystemProtectionType
          ) {
            <mat-radio-button
              [value]="networkAndSystemProtectionType"
              [attr.data-testId]="networkAndSystemProtectionType">
              {{
                'STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.' +
                  networkAndSystemProtectionType | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="networkAndSystemProtection_location"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
        @if (
          form.get('networkAndSystemProtection_location')?.value ===
          NETWORK_AND_SYSTEM_PROTECTION_METER_LOCATION
        ) {
          <div class="input-container">
            <mat-form-field
              appearance="outline"
              class="full-width-flex-element">
              <mat-label
                >{{
                  'STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.MANUFACTURER'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="networkAndSystemProtection_manufacturer"
                formControlName="networkAndSystemProtection_manufacturer"
                data-testId="network-and-system-protection_manufacturer" />
              <mat-error
                appErrorMessages="networkAndSystemProtection_manufacturer"></mat-error>
            </mat-form-field>
          </div>
          <div class="input-container">
            <mat-form-field
              appearance="outline"
              class="full-width-flex-element">
              <mat-label
                >{{
                  'STORAGE_CONTROLLABLE_DETAILS.NETWORK_AND_SYSTEM_PROTECTION.TYPE'
                    | translate
                }}
              </mat-label>
              <input
                matInput
                name="networkAndSystemProtection_type"
                formControlName="networkAndSystemProtection_type"
                data-testId="network-and-system-protection_type" />
              <mat-error
                appErrorMessages="networkAndSystemProtection_type"></mat-error>
            </mat-form-field>
          </div>
        }
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TITLE'
              | translate
                : {
                    date:
                      '01.01.2024'
                      | date: 'shortDate' : '' : currentLanguageCulture,
                  }
          }}</span>
          <app-info-tooltip
            [tooltipText]="
              'STORAGE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.TOOLTIP'
                | translate
                  : {
                      date:
                        '01.01.2024'
                        | date: 'shortDate' : '' : currentLanguageCulture,
                    }
            ">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="existingSystem"
          aria-labelledby="existingSystem-radio-group-label"
          class="input-container">
          @for (existingSystem of EXISTING_SYSTEM; track existingSystem) {
            <mat-radio-button
              [value]="existingSystem"
              [attr.data-testId]="existingSystem">
              {{
                'STORAGE_CONTROLLABLE_DETAILS.EXISTING_SYSTEM.' + existingSystem
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="existingSystem"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>

      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.CONTROL_TYPE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="controlType"
          aria-labelledby="controlType-radio-group-label"
          class="input-container">
          @for (controlType of CONTROL_TYPE; track controlType) {
            <mat-radio-button
              [value]="controlType"
              [attr.data-testId]="controlType">
              {{
                'STORAGE_CONTROLLABLE_DETAILS.CONTROL_TYPE.' + controlType
                  | translate
              }}
            </mat-radio-button>
          }
          <mat-error appErrorMessages="controlType" [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>

    <div class="space"></div>

    <div class="two-column-layout-container">
      <div class="two-column-layout-item">
        <h2 class="tooltip-heading-label">
          <span appRequiredSuffix>{{
            'STORAGE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TITLE' | translate
          }}</span>
          <app-info-tooltip
            tooltipText="STORAGE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.TOOLTIP">
          </app-info-tooltip>
        </h2>
        <mat-radio-group
          color="primary"
          formControlName="networkFeeModule"
          aria-labelledby="networkFeeModule-radio-group-label"
          class="input-container">
          @for (
            networkFeeModule of NETWORK_FEE_MODULE;
            track networkFeeModule
          ) {
            <mat-radio-button
              [value]="networkFeeModule"
              [attr.data-testId]="networkFeeModule">
              {{
                'STORAGE_CONTROLLABLE_DETAILS.NETWORK_FEE_MODULE.' +
                  networkFeeModule | translate
              }}
            </mat-radio-button>
          }
          <mat-error
            appErrorMessages="networkFeeModule"
            [hideError]="!allTouched">
          </mat-error>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
