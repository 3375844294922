import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { SharedModule } from '@app/modules/shared/shared.module';
import { AppStateService } from '@app/services/app-state.service';
import { CustomDateAdapter } from '@app/services/custom-date-adapter.service';
import { DateHelperService } from '@app/services/date-helper.service';
import { RouteService } from '@app/services/route.service';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, map, take } from 'rxjs';

@Component({
  selector: 'app-charging-device-system-information',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    RequiredSuffixDirective,
    SharedModule,
    TranslatePipe,
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],

  templateUrl: './charging-device-system-information.component.html',
})
export class ChargingDeviceSystemInformationComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #dateHelperService = inject(DateHelperService);

  public form!: FormGroup;
  public allTouched = false;
  public readonly dateForTomorrow = this.#dateHelperService.getFutureDate(1);
  public readonly errorMessageForInvalidDateFormat =
    this.#dateHelperService.getErrorMessageForInvalidDateFormat();
  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      plannedCommissioningDate: null,
      controllable: [null, Validators.required],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(
        map(state => state.formData.chargingDeviceSystemInformation),
        filter(Boolean),
        take(1)
      )
      .subscribe(chargingDeviceSystemInformation =>
        this.form.patchValue(chargingDeviceSystemInformation)
      );
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState(): void {
    this.#appStateService.updateFormData({
      chargingDeviceSystemInformation: this.form.value,
    });
  }
}
