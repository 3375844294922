<app-form-card
  titleIcon="category"
  cardTitle="OTHER_2.TYPE.HEADING"
  dataTestId="other-type-heading">
  <h2 class="full-width">
    {{ 'OTHER_2.TYPE.DESCRIPTION' | translate }}
  </h2>
  <app-selection-group>
    @for (type of typeSelectionList; track type) {
      <app-selection-card
        class="selection-card"
        [icon]="type.icon"
        [svgIcon]="type.svgIcon"
        [label]="type.label"
        [selected]="selectedType === type.type"
        (click)="selectType(type.type)"
        [attr.data-testid]="type.type">
      </app-selection-card>
    }
    @if (allTouched | nextButtonDisabled: form.invalid) {
      <mat-error>{{ 'VALIDATION.REQUIRED_SELECTION' | translate }}</mat-error>
    }
  </app-selection-group>

  @if (selectedType) {
    <div class="space"></div>
    <app-hint [warning]="true" [showOutline]="true">
      {{ 'PRODUCTS.OTHER_2_SELECTED.INSTALLER_HINT' | translate }}
    </app-hint>
    <div class="space"></div>
    @switch (selectedType) {
      @case (OTHER_TYPE.NEW) {
        <app-hint>
          {{ 'PRODUCTS.OTHER_2_SELECTED.INFO_NEW' | translate }}</app-hint
        >
      }
      @case (OTHER_TYPE.CHANGE) {
        <app-hint>
          {{ 'PRODUCTS.OTHER_2_SELECTED.INFO_CHANGE' | translate }}</app-hint
        >
      }
    }
  }

  <app-form-navigation
    slot="navigation"
    [nextConfig]="{
      disabled: allTouched | nextButtonDisabled: form.invalid,
    }"
    (previous)="previous()"
    (next)="next()">
  </app-form-navigation>
</app-form-card>
