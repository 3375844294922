<h2 class="tooltip-heading-label">
  <label class="label-required">{{
    'PRODUCTS.PV_SELECTED.IS_BALCONY_PV' | translate
  }}</label>
  <app-info-tooltip tooltipText="PRODUCTS.PV_SELECTED.IS_BALCONY_PV_TOOLTIP">
  </app-info-tooltip>
</h2>

<ng-content />

@if (showHints()) {
  <div class="space"></div>

  <app-hint [showOutline]="true" [warning]="true">
    {{
      'PRODUCTS.PV_SELECTED.YOU_WILL_NEED_YOUR_INSTALLER_FOR_THE_NEXT_STEPS'
        | translate
    }}
  </app-hint>

  <div class="space"></div>

  <app-hint>
    {{ 'PRODUCTS.PV_SELECTED.PREPARE_YOUR_DOCUMENTS_FOR_UPLOAD' | translate }}
    <ul class="list-container">
      <li>
        {{ 'PRODUCTS.PV_SELECTED.LOCATION_PLAN' | translate }}
      </li>
      <li>
        {{ 'PRODUCTS.PV_SELECTED.MEASUREMENT_CONCEPT' | translate }}
      </li>
      <li>
        {{ 'PRODUCTS.PV_SELECTED.UNIT_CERTIFICATE' | translate }}
      </li>
    </ul>
  </app-hint>
}
