import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import {
  CHANGE_SYSTEM_TYPE,
  ChangeOtherDetailsDto,
  OTHER_CHANGE_TYPE,
  OTHER_SYSTEM_TYPE,
} from '@app/models/registration-form';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-other-details',
  standalone: true,
  imports: [
    MatCheckbox,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    RequiredSuffixDirective,
  ],
  templateUrl: './other-details.component.html',
})
export class OtherDetailsComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);

  public form!: FormGroup;
  public allTouched = false;
  public readonly CHANGE_SYSTEM_TYPE = Object.values(CHANGE_SYSTEM_TYPE);
  public readonly CHANGE_SYSTEM_TYPE_OTHER = CHANGE_SYSTEM_TYPE.OTHER;
  public readonly OTHER_CHANGE_TYPE = Object.values(OTHER_CHANGE_TYPE);
  public readonly OTHER_CHANGE_TYPE_POWER_INCREASE =
    OTHER_CHANGE_TYPE.POWER_INCREASE;
  public readonly maxLength = CustomValidators.LONG_TEXT_MAX_LENGTH;

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
    this.#watchForm();
  }

  #createForm(): void {
    this.form = this.#formBuilder.group({
      changeSystem: [null, [Validators.required]],
      otherChangeSystem: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
      controllable: [null, Validators.required],
      changeType: [null, Validators.required],
      power: [null, Validators.required],
      plannedPower: [null, Validators.required],
      description: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.longText,
            Validators.required,
          ],
        },
      ],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(filter(Boolean), take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(form => {
        if (form.formData.otherDetails) {
          this.form.patchValue(form.formData.otherDetails);
        }
        this.#configureOtherSystemTypeInput();
        this.#configurePowerInputs();
      });
  }

  #watchForm(): void {
    this.form
      .get('changeSystem')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#configureOtherSystemTypeInput());
    this.form
      .get('changeType')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#configurePowerInputs());
  }

  #configureOtherSystemTypeInput() {
    if (this.form.get('changeSystem')?.value === OTHER_SYSTEM_TYPE.OTHER) {
      this.form.get('otherChangeSystem')?.enable();
    } else {
      this.form.get('otherChangeSystem')?.disable();
    }
  }

  #configurePowerInputs() {
    if (
      this.form.get('changeType')?.value === OTHER_CHANGE_TYPE.POWER_INCREASE
    ) {
      this.form.get('power')?.enable();
      this.form.get('plannedPower')?.enable();
    } else {
      this.form.get('power')?.disable();
      this.form.get('plannedPower')?.disable();
    }
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState() {
    const otherDetails: ChangeOtherDetailsDto = this.form.value;
    this.#appStateService.updateFormData({
      otherDetails: otherDetails,
    });
  }
}
