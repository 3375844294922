import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NextButtonDisabledPipe } from '@app/shared/pipes/next-button-disabled.pipe';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/modules/shared/shared.module';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { AppStateService } from '@app/services/app-state.service';
import { RouteService } from '@app/services/route.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RequiredSuffixDirective } from '@app/shared/directives/required-suffix.directive';
import {
  CONTROL_TYPE,
  ControllableDetailsDto,
  CONTROLLER,
  EXISTING_SYSTEM,
  NETWORK_FEE_MODULE,
} from '@app/models/registration-form';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { getLanguageLocaleCulture } from '@app/constants/language';

@Component({
  selector: 'app-other-controllable-system',
  standalone: true,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    NextButtonDisabledPipe,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    RequiredSuffixDirective,
  ],
  templateUrl: './other-controllable-system.component.html',
})
export class OtherControllableSystemComponent implements OnInit {
  readonly #appStateService: AppStateService = inject(AppStateService);
  readonly #formBuilder: FormBuilder = inject(FormBuilder);
  readonly #routeService: RouteService = inject(RouteService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);

  public form!: FormGroup;
  public allTouched = false;
  public readonly CONTROLLER_COMPETITIVE_METERING_POINT_OPERATOR =
    CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR;
  public readonly CONTROLLER = Object.values(CONTROLLER);
  public readonly EXISTING_SYSTEM = Object.values(EXISTING_SYSTEM);
  public readonly CONTROL_TYPE = Object.values(CONTROL_TYPE);
  public readonly NETWORK_FEE_MODULE = Object.values(NETWORK_FEE_MODULE);

  public currentLanguageCulture = getLanguageLocaleCulture(
    this.#translateService.currentLang
  );

  public ngOnInit(): void {
    this.#createForm();
    this.#updateForm();
    this.#watchForm();
  }

  #createForm(): void {
    // ControllableDetailsDto
    this.form = this.#formBuilder.group({
      totalMinimumPower: [null, Validators.required],
      compliant: [null, Validators.required],
      controller: [null, Validators.required],
      existingSystem: [null, Validators.required],
      controlType: [null, Validators.required],
      networkFeeModule: [null, Validators.required],
      controllerName: [
        null,
        {
          updateOn: 'blur',
          validators: [
            CustomValidators.trimValidator,
            CustomValidators.shortText,
            Validators.required,
          ],
        },
      ],
    });
  }

  #updateForm(): void {
    this.#appStateService
      .observeState()
      .pipe(filter(Boolean), take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(form => {
        if (form.formData.otherControllableDetails) {
          this.form.patchValue(form.formData.otherControllableDetails);
        }
        this.#configureControllerInput();
      });
  }

  #watchForm(): void {
    this.form
      .get('controller')
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#configureControllerInput());
  }

  #configureControllerInput() {
    if (
      this.form.get('controller')?.value ===
      CONTROLLER.COMPETITIVE_METERING_POINT_OPERATOR
    ) {
      this.form.get('controllerName')?.enable();
    } else {
      this.form.get('controllerName')?.disable();
    }
  }

  public previous(): void {
    this.#updateState();
    this.#routeService.navigateToPreviousStep();
  }

  public next(): void {
    if (this.form.valid) {
      this.#updateState();
      this.#routeService.navigateToNextStep();
    } else {
      this.form.markAllAsTouched();
      this.allTouched = true;
    }
  }

  #updateState() {
    const otherControllableDetails: ControllableDetailsDto = this.form.value;
    this.#appStateService.updateFormData({
      otherControllableDetails: otherControllableDetails,
    });
  }
}
