import { PvGenerationUnit } from './../models/form-data.interface';
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { inject, Injectable } from '@angular/core';
import {
  DocumentDetailsWithFile,
  DocumentWithFile,
  FLOW,
  FLOW_MODULE,
  FormChangeGridConnectionDetails,
  FormDataObject,
  FormNewGridConnectionDetails,
  GridConnectionConsumer,
  OtherCustomerFacility,
} from '@app/models/form-data.interface';
import {
  Address,
  ChangeGasDetails,
  ChangeGridConnectionDetails,
  ChangeHeatDetails,
  ChangeOtherDetailsDto,
  ChangeWaterDetails,
  ChargingDevice2,
  ConstructionElectricity,
  ConstructionWater,
  Consumer,
  Contact,
  ContactDetails,
  ControllableDetailsDto,
  DetachGasDetails,
  DetachHeatDetails,
  DetachSewageDetails,
  DetachWaterDetails,
  Document,
  DocumentDetails,
  ElectricityRegistrationFlowDetails,
  FORM_TYPE,
  GAS_OPTION,
  GasConnectionDetails,
  GasRegistrationFlowDetails,
  GRID_CONNECTION_TYPE,
  GridConnection,
  GridConnectionDetails,
  HEAT_OPTION,
  HeatPump,
  HeatRegistrationFlowDetails,
  NewGasDetails,
  NewGridConnectionDetails,
  NewHeatDetails,
  NewOtherDetailsDto,
  NewSewageDetails,
  NewWaterDetails,
  Other2,
  OTHER_TYPE,
  OtherDetails,
  OtherSystemDetailsDto,
  PowerChangeDetails,
  PowerGridConnectionDetails,
  PV_2,
  PVCustomerFacilityDto,
  PVInverterDto,
  RegistrationFlow,
  RegistrationFlowDetails,
  RegistrationFlowDetailsWithDocuments,
  RegistrationForm,
  Sewage,
  SEWAGE_OPTION,
  SewageDetails,
  Storage2,
  Water,
  WATER_OPTION,
  WaterDetails,
  WaterRegistrationFlowDetails,
} from '@app/models/registration-form';
import { PartialFormData } from './app-state.service';
import { DocumentMapperService } from './document-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class FormMapperService {
  #documentsUploadEnabled?: boolean;
  #formData?: FormDataObject;
  #documentMapperService = inject(DocumentMapperService);

  public mapFiles(formDataObject: PartialFormData): File[] {
    const mappedDocuments =
      formDataObject.documentDetails?.documents.map(
        document => document.file
      ) ?? [];

    const powerOfAttorneyDocument =
      formDataObject.contact?.powerOfAttorneyDocument;
    if (
      formDataObject.selectedFlow === FLOW.REGISTRATION &&
      formDataObject.contact?.powerOfAttorney &&
      powerOfAttorneyDocument
    ) {
      mappedDocuments.push(powerOfAttorneyDocument.file);
    }

    return mappedDocuments;
  }

  public mapFormData(
    customerConfigurationId: string,
    formDataObject: PartialFormData,
    documentsUploadEnabled: boolean = true
  ): RegistrationForm {
    this.#formData = formDataObject;
    this.#documentsUploadEnabled = documentsUploadEnabled;
    return {
      flow: this.#mapFlow(formDataObject, documentsUploadEnabled),
      location: {
        address: {
          zipCode: formDataObject.address?.zipCode!,
          city: formDataObject.address?.city!,
          street: formDataObject.address?.street,
          streetNumber: formDataObject.address?.streetNumber,
        },
        district: formDataObject.address?.additionalAddressData?.district,
        parcel: formDataObject.address?.additionalAddressData?.parcel,
        parcelNumber:
          formDataObject.address?.additionalAddressData?.parcelNumber,
      },
      contact: this.#mapContact(formDataObject),
      metadata: {
        customerConfigurationId: customerConfigurationId,
      },
      requestType: FLOW.REGISTRATION,
    };
  }

  #mapFlow(
    formDataObject: PartialFormData,
    documentsUploadEnabled: boolean = true
  ): RegistrationFlow {
    return {
      flowDetails: this.#mapFlowDetails(formDataObject, documentsUploadEnabled),
      module: formDataObject.selectedModule!,
    };
  }

  #mapFlowDetails(
    formDataObject: PartialFormData,
    documentsUploadEnabled: boolean = true
  ): RegistrationFlowDetailsWithDocuments {
    let flowDetails: RegistrationFlowDetails;
    switch (formDataObject.selectedModule) {
      case FLOW_MODULE.ELECTRICITY:
        flowDetails =
          this.#mapElectricityRegistrationFlowDetails(formDataObject);
        break;
      case FLOW_MODULE.GAS:
        flowDetails = this.#mapGasRegistrationFlowDetails(formDataObject);
        break;
      case FLOW_MODULE.WATER:
        flowDetails = this.#mapWaterRegistrationFlowDetails(formDataObject);
        break;
      case FLOW_MODULE.HEAT:
        flowDetails = this.#mapHeatRegistrationFlowDetails(formDataObject);
        break;
      default:
        break;
    }
    return {
      documentDetails: this.mapDocumentDetails(
        formDataObject.documentDetails,
        documentsUploadEnabled
      ),
      ...flowDetails!,
    };
  }

  #mapElectricityRegistrationFlowDetails(
    formDataObject: PartialFormData
  ): ElectricityRegistrationFlowDetails {
    let electricityRegistrationFlowDetails: ElectricityRegistrationFlowDetails;
    switch (formDataObject.formType) {
      case FORM_TYPE.CHARGING_DEVICE_2:
        electricityRegistrationFlowDetails =
          this.#mapChargingDevice2(formDataObject);
        break;
      case FORM_TYPE.GRID_CONNECTION:
        electricityRegistrationFlowDetails =
          this.#mapGridConnection(formDataObject);
        break;
      case FORM_TYPE.HEAT_PUMP:
        electricityRegistrationFlowDetails = this.#mapHeatPump(formDataObject);
        break;
      case FORM_TYPE.CONSTRUCTION_ELECTRICITY:
        electricityRegistrationFlowDetails =
          this.#mapConstructionElectricity(formDataObject);
        break;
      case FORM_TYPE.PV_2:
        electricityRegistrationFlowDetails = this.#mapPV(formDataObject);
        break;
      case FORM_TYPE.STORAGE_2:
        electricityRegistrationFlowDetails = this.#mapStorage2(formDataObject);
        break;
      case FORM_TYPE.OTHER_2:
        electricityRegistrationFlowDetails = this.#mapOther2(formDataObject);
        break;
    }
    return electricityRegistrationFlowDetails!;
  }

  #mapGasRegistrationFlowDetails(
    partialFormData: PartialFormData
  ): GasRegistrationFlowDetails {
    return {
      type: FORM_TYPE.GAS,
      details: this.#mapGasDetails(partialFormData),
    };
  }

  #mapGasDetails(partialFormData: PartialFormData): GasConnectionDetails {
    let gasDetails: GasConnectionDetails;
    switch (partialFormData.gasOptions?.gasOption) {
      case GAS_OPTION.NEW:
        gasDetails = this.#mapNewGasDetails(partialFormData);
        break;
      case GAS_OPTION.CHANGE:
        gasDetails = this.#mapChangeGasDetails(partialFormData);
        break;
      case GAS_OPTION.DETACH:
        gasDetails = this.#mapDetachGasDetails(partialFormData);
        break;
    }
    return gasDetails!;
  }

  #mapNewGasDetails({
    gasBuildingDetails,
    gasPowerRequirement,
  }: PartialFormData): NewGasDetails {
    return {
      buildingDetails: {
        buildingType: gasBuildingDetails?.buildingType!,
        buildingStructure: gasBuildingDetails?.buildingStructure!,
        houseInlet: gasBuildingDetails?.houseInlet,
        currentEnergySource: gasBuildingDetails?.currentEnergySource,
      },
      powerDemand: gasPowerRequirement?.powerDemand!,
      notes: gasPowerRequirement?.notes,
      type: GAS_OPTION.NEW,
    };
  }

  #mapChangeGasDetails({
    gasOptions,
    gasBuildingDetails,
  }: PartialFormData): ChangeGasDetails {
    return {
      changePower: gasOptions?.changePower!,
      relocate: gasOptions?.relocate!,
      currentPowerDemand: gasOptions?.currentPowerDemand,
      plannedPowerDemand: gasOptions?.plannedPowerDemand,
      ditchLength: gasOptions?.ditchLength,
      ...(gasOptions?.relocate && {
        buildingDetails: {
          buildingType: gasBuildingDetails?.buildingType!,
          buildingStructure: gasBuildingDetails?.buildingStructure!,
          houseInlet: gasBuildingDetails?.houseInlet,
          currentEnergySource: gasBuildingDetails?.currentEnergySource,
        },
      }),
      type: GAS_OPTION.CHANGE,
    };
  }

  #mapDetachGasDetails({ gasOptions }: PartialFormData): DetachGasDetails {
    return {
      partialDeconstructionDesired: gasOptions?.partialDeconstructionDesired!,
      meterNumber: gasOptions?.meterNumber!,
      type: GAS_OPTION.DETACH,
    };
  }

  #mapHeatRegistrationFlowDetails(
    partialFormData: PartialFormData
  ): HeatRegistrationFlowDetails {
    let heatRegistrationFlowDetails: HeatRegistrationFlowDetails;
    switch (partialFormData.heatOptions?.heatOption!) {
      case HEAT_OPTION.NEW:
        heatRegistrationFlowDetails = this.#mapNewHeatDetails(partialFormData);
        break;
      case HEAT_OPTION.CHANGE:
        heatRegistrationFlowDetails =
          this.#mapChangeHeatDetails(partialFormData);
        break;
      case HEAT_OPTION.DETACH:
        heatRegistrationFlowDetails =
          this.#mapDetachHeatDetails(partialFormData);
        break;
    }
    return heatRegistrationFlowDetails!;
  }

  #mapNewHeatDetails({
    heatBuildingDetails,
    heatRequirementDetails,
  }: PartialFormData): NewHeatDetails {
    return {
      buildingDetails: heatBuildingDetails!,
      heatOutputDemand: heatRequirementDetails?.heatOutputDemand!,
      otherConsumers: heatRequirementDetails?.otherConsumers,
      otherConsumersPower: heatRequirementDetails?.otherConsumersPower,
      notes: heatRequirementDetails?.notes,
      type: FORM_TYPE.HEAT_NEW,
    };
  }

  #mapChangeHeatDetails({
    heatOptions,
    heatBuildingDetails,
  }: PartialFormData): ChangeHeatDetails {
    return {
      changeHeatPower: heatOptions?.changeHeatPower!,
      changeOther: heatOptions?.changeOther!,
      currentHeatPower: heatOptions?.currentHeatPower,
      plannedHeatPower: heatOptions?.plannedHeatPower,
      additionalHeatDemand: heatOptions?.additionalHeatDemand,
      otherChanges: heatOptions?.otherChanges,
      buildingDetails: heatBuildingDetails!,
      type: FORM_TYPE.HEAT_CHANGE,
    };
  }

  #mapDetachHeatDetails({ heatOptions }: PartialFormData): DetachHeatDetails {
    return {
      permanentDeconstruction: heatOptions?.permanentDeconstruction!,
      meterNumber: heatOptions?.meterNumber!,
      deconstructionUntil:
        heatOptions?.deconstructionUntil &&
        this.#getUtcDate(heatOptions?.deconstructionUntil),
      type: FORM_TYPE.HEAT_DETACH,
    };
  }

  #mapWaterRegistrationFlowDetails(
    partialFormData: PartialFormData
  ): WaterRegistrationFlowDetails {
    let waterRegistrationFlowDetails: WaterRegistrationFlowDetails;
    switch (partialFormData.formType) {
      case FORM_TYPE.WATER:
        waterRegistrationFlowDetails = this.#mapWater(partialFormData);
        break;
      case FORM_TYPE.SEWAGE:
        waterRegistrationFlowDetails = this.#mapSewage(partialFormData);
        break;
      case FORM_TYPE.CONSTRUCTION_WATER:
        waterRegistrationFlowDetails =
          this.#mapConstructionWater(partialFormData);
        break;
    }
    return waterRegistrationFlowDetails!;
  }

  #mapWater(partialFormData: PartialFormData): Water {
    let waterDetails: WaterDetails;
    switch (partialFormData.waterOptions?.waterOption!) {
      case WATER_OPTION.NEW:
        waterDetails = this.#mapNewWaterDetails(partialFormData);
        break;
      case WATER_OPTION.CHANGE:
        waterDetails = this.#mapChangeWaterDetails(partialFormData);
        break;
      case WATER_OPTION.DETACH:
        waterDetails = this.#mapDetachWaterDetails(partialFormData);
        break;
    }
    return {
      type: FORM_TYPE.WATER,
      details: waterDetails,
    };
  }

  #mapNewWaterDetails({
    waterBuildingDetails,
    waterRequirementDetails,
  }: PartialFormData): NewWaterDetails {
    return {
      buildingDetails: {
        buildingType: waterBuildingDetails?.buildingType!,
        buildingStructure: waterBuildingDetails?.buildingStructure!,
      },
      numberOfHousingUnits: waterRequirementDetails?.numberOfHousingUnits!,
      numberOfResidents: waterRequirementDetails?.numberOfResidents,
      numberOfFloors: waterRequirementDetails?.numberOfFloors!,
      extractionPointHeight: waterRequirementDetails?.extractionPointHeight!,
      flowRate: waterRequirementDetails?.flowRate,
      additionalConsumers: waterRequirementDetails?.additionalConsumers,
      type: WATER_OPTION.NEW,
    };
  }

  #mapChangeWaterDetails({
    waterOptions,
    waterBuildingDetails,
  }: PartialFormData): ChangeWaterDetails {
    return {
      changeFlowRate: !!waterOptions?.changeFlowRate!,
      relocate: !!waterOptions?.relocate!,
      currentFlowRate: waterOptions?.currentFlowRate,
      plannedFlowRate: waterOptions?.plannedFlowRate,
      additionalDemand: waterOptions?.additionalDemand,
      ditchLength: waterOptions?.ditchLength,
      buildingDetails: {
        buildingType: waterBuildingDetails?.buildingType!,
        buildingStructure: waterBuildingDetails?.buildingStructure!,
      },
      type: WATER_OPTION.CHANGE,
    };
  }

  #mapDetachWaterDetails({
    waterOptions,
  }: PartialFormData): DetachWaterDetails {
    return {
      permanentDeconstruction: waterOptions?.permanentDeconstruction!,
      meterNumber: waterOptions?.meterNumber!,
      deconstructionUntil:
        waterOptions?.deconstructionUntil &&
        this.#getUtcDate(waterOptions?.deconstructionUntil),
      type: WATER_OPTION.DETACH,
    };
  }

  #mapSewage(partialFormData: PartialFormData): Sewage {
    let sewageDetails: SewageDetails;
    switch (partialFormData.sewageOptions?.sewageOption!) {
      case SEWAGE_OPTION.NEW:
        sewageDetails = this.#mapNewSewageDetails(partialFormData);
        break;
      case SEWAGE_OPTION.DETACH:
        sewageDetails = this.#mapDetachSewageDetails(partialFormData);
        break;
    }
    return {
      type: FORM_TYPE.SEWAGE,
      details: sewageDetails,
    };
  }

  #mapNewSewageDetails({
    sewageType,
    waterBuildingDetails,
  }: PartialFormData): NewSewageDetails {
    return {
      sewageType: sewageType!,
      buildingDetails: {
        buildingType: waterBuildingDetails?.buildingType!,
        buildingStructure: waterBuildingDetails?.buildingStructure!,
      },
      type: SEWAGE_OPTION.NEW,
    };
  }

  #mapDetachSewageDetails({
    sewageOptions,
  }: PartialFormData): DetachSewageDetails {
    return {
      permanentDeconstruction: sewageOptions?.permanentDeconstruction!,
      meterNumber: sewageOptions?.meterNumber!,
      deconstructionUntil:
        sewageOptions?.deconstructionUntil &&
        this.#getUtcDate(sewageOptions?.deconstructionUntil),
      type: SEWAGE_OPTION.DETACH,
    };
  }

  #mapConstructionWater({
    constructionWaterRequirements,
  }: PartialFormData): ConstructionWater {
    return {
      flowRate: constructionWaterRequirements?.flowRate!,
      convertedSpace: constructionWaterRequirements?.convertedSpace!,
      desiredInstallationDate: this.#getUtcDate(
        constructionWaterRequirements?.desiredInstallationDate!
      ),
      desiredDeconstructionDate:
        constructionWaterRequirements?.desiredDeconstructionDate &&
        this.#getUtcDate(
          constructionWaterRequirements.desiredDeconstructionDate
        ),
      notes: constructionWaterRequirements?.notes!,
      type: FORM_TYPE.CONSTRUCTION_WATER,
    };
  }

  #getUtcDate(formDate: Date): string {
    return new Date(
      Date.UTC(
        formDate.getFullYear(),
        formDate.getMonth(),
        formDate.getDate(),
        0,
        0,
        0
      )
    ).toISOString();
  }

  #mapPV({
    pvCustomerFacility,
    pvInverter,
    pvGenerationUnit,
  }: {
    pvCustomerFacility?: PVCustomerFacilityDto;
    pvInverter?: PVInverterDto;
    pvGenerationUnit?: PvGenerationUnit;
  }): PV_2 {
    return {
      type: FORM_TYPE.PV_2,
      inverter: pvInverter!,
      customerFacility: pvCustomerFacility!,
      generationUnit: {
        ...pvGenerationUnit!,
        networkAndSystemProtectionDetails: {
          location: pvGenerationUnit!.networkAndSystemProtectionDetailsLocation,
          manufacturer:
            pvGenerationUnit!.networkAndSystemProtectionDetailsManufacturer,
          type: pvGenerationUnit!.networkAndSystemProtectionDetailsType,
        },
      },
    };
  }

  #mapChargingDevice2({
    chargingDeviceSystemInformation,
    chargingDeviceControllableDetails,
    chargingDeviceInformation,
  }: PartialFormData): ChargingDevice2 {
    return {
      type: FORM_TYPE.CHARGING_DEVICE_2,
      controllable: chargingDeviceSystemInformation!.controllable,
      plannedCommissioningDate:
        chargingDeviceSystemInformation!.plannedCommissioningDate,
      controllableDetails: chargingDeviceControllableDetails,
      details: chargingDeviceInformation!,
    };
  }

  #mapHeatPump({ heatPump }: PartialFormData): HeatPump {
    return {
      systemManufacturer: heatPump?.systemManufacturer,
      systemType: heatPump?.systemType,
      systemPower: heatPump?.systemPower!,
      meterRequired: heatPump?.meterRequired!,
      meterNumber: heatPump?.meterNumber,
      connectionPowerIncrease: heatPump?.connectionPowerIncrease!,
      currentPowerDemand: heatPump?.currentPowerDemand,
      plannedPowerDemand: heatPump?.plannedPowerDemand,
      controllablePower: heatPump?.controllablePower!,
      notes: heatPump?.notes,
      type: FORM_TYPE.HEAT_PUMP,
    };
  }

  #mapConstructionElectricity({
    constructionElectricity,
  }: PartialFormData): ConstructionElectricity {
    return {
      ...constructionElectricity!,
      type: FORM_TYPE.CONSTRUCTION_ELECTRICITY,
    };
  }

  #mapStorage2({
    storageCustomerFacility,
    storageDetailsDto,
    storageControllableDetails,
    storageNetworkAndSystemProtectionDetails,
  }: PartialFormData): Storage2 {
    return {
      controllable: storageCustomerFacility?.controllable!,
      plannedCommissioningDate:
        storageCustomerFacility?.plannedCommissioningDate!,
      formOfFeedInSale: storageCustomerFacility?.formOfFeedInSale!,
      controllableDetails: storageCustomerFacility?.controllable
        ? storageControllableDetails
        : undefined,
      networkAndSystemProtectionDetails:
        storageNetworkAndSystemProtectionDetails,
      details: storageDetailsDto!,
      type: FORM_TYPE.STORAGE_2,
    };
  }

  #mapOther2({
    otherType,
    otherCustomerFacility,
    otherControllableDetails,
    otherSystemDetails,
    otherDetails,
  }: PartialFormData): Other2 {
    return {
      details: this.#mapOtherDetails(
        otherType!,
        otherCustomerFacility,
        otherControllableDetails,
        otherSystemDetails,
        otherDetails
      ),
      type: FORM_TYPE.OTHER_2,
    };
  }

  #mapOtherDetails(
    otherType: OTHER_TYPE,
    otherCustomerFacility?: OtherCustomerFacility,
    otherControllableDetails?: ControllableDetailsDto,
    otherSystemDetails?: OtherSystemDetailsDto,
    otherDetails?: Omit<ChangeOtherDetailsDto, 'type'>
  ): OtherDetails {
    switch (otherType) {
      case OTHER_TYPE.NEW:
        return this.#mapNewOtherDetailsDto(
          otherCustomerFacility!,
          otherControllableDetails!,
          otherSystemDetails!
        );
      case OTHER_TYPE.CHANGE:
        return this.#mapChangeOtherDetailsDto(otherDetails!);
    }
  }

  #mapNewOtherDetailsDto(
    otherCustomerFacility: OtherCustomerFacility,
    otherControllableDetails: ControllableDetailsDto,
    otherSystemDetails: OtherSystemDetailsDto
  ): NewOtherDetailsDto {
    return {
      controllable: otherCustomerFacility.controllable,
      plannedCommissioningDate: otherCustomerFacility.plannedCommissioningDate,
      controllableDetails: otherControllableDetails,
      details: otherSystemDetails,
      type: OTHER_TYPE.NEW,
    };
  }
  #mapChangeOtherDetailsDto(
    otherDetails: Omit<ChangeOtherDetailsDto, 'type'>
  ): ChangeOtherDetailsDto {
    return {
      ...otherDetails,
      type: OTHER_TYPE.CHANGE,
    };
  }

  #mapGridConnection({
    gridConnectionConsumer,
    gridConnectionType,
    newGridConnectionDetails,
    powerChangeDetails,
    changeGridConnectionDetails,
  }: PartialFormData): GridConnection {
    return {
      type: FORM_TYPE.GRID_CONNECTION,
      details: this.#mapGridConnectionDetails(
        gridConnectionType!,
        gridConnectionConsumer!,
        newGridConnectionDetails!,
        powerChangeDetails!,
        changeGridConnectionDetails!
      ),
    };
  }

  #mapGridConnectionDetails(
    gridConnectionType: GRID_CONNECTION_TYPE,
    gridConnectionConsumer: GridConnectionConsumer,
    newGridConnectionDetails: FormNewGridConnectionDetails,
    powerChangeDetails: PowerChangeDetails,
    changeGridConnectionDetails: FormChangeGridConnectionDetails
  ): GridConnectionDetails {
    switch (gridConnectionType) {
      case GRID_CONNECTION_TYPE.NEW:
        return this.#mapNewGridConnectionDetails(
          newGridConnectionDetails,
          gridConnectionConsumer
        );
      case GRID_CONNECTION_TYPE.POWER:
        return this.#mapPowerGridConnectionDetails(powerChangeDetails);
      case GRID_CONNECTION_TYPE.CHANGE:
        return this.#mapChangeGridConnectionDetails(
          changeGridConnectionDetails,
          powerChangeDetails
        );
      case GRID_CONNECTION_TYPE.DETACH:
        return { type: GRID_CONNECTION_TYPE.DETACH };
    }
  }

  #mapNewGridConnectionDetails(
    newGridConnectionDetails: FormNewGridConnectionDetails,
    gridConnectionConsumer: GridConnectionConsumer
  ): NewGridConnectionDetails {
    return {
      buildingType: newGridConnectionDetails.buildingType,
      basementAvailable: newGridConnectionDetails.basementAvailable,
      externalConnection: newGridConnectionDetails.externalConnection,
      flatCount: newGridConnectionDetails.flatCount,
      area: newGridConnectionDetails.area,
      consumerDetails: {
        consumers: gridConnectionConsumer
          ? this.#mapConsumerType(gridConnectionConsumer)
          : [],
        systemPower: gridConnectionConsumer.systemPower,
        connectionFuse: gridConnectionConsumer.connectionFuse,
        maxConcurrentPower: gridConnectionConsumer.maxConcurrentPower,
        additionalConsumers: gridConnectionConsumer.additionalConsumers,
      },
      type: GRID_CONNECTION_TYPE.NEW,
    };
  }

  #mapPowerGridConnectionDetails(
    powerChangeDetails: PowerChangeDetails
  ): PowerGridConnectionDetails {
    return {
      details: this.#mapPowerChangeDetails(powerChangeDetails),
      type: GRID_CONNECTION_TYPE.POWER,
    };
  }

  #mapPowerChangeDetails(
    powerChangeDetails: PowerChangeDetails
  ): PowerChangeDetails {
    return powerChangeDetails;
  }

  #mapChangeGridConnectionDetails(
    changeGridConnectionDetails: FormChangeGridConnectionDetails,
    powerChangeDetails: PowerChangeDetails
  ): ChangeGridConnectionDetails {
    const mappedPowerChangeDetails =
      changeGridConnectionDetails?.totalPowerChanged && powerChangeDetails
        ? powerChangeDetails
        : null;

    return {
      ...changeGridConnectionDetails,
      powerChangeDetails: mappedPowerChangeDetails,
      type: GRID_CONNECTION_TYPE.CHANGE,
    };
  }

  #mapConsumerType({ type }: GridConnectionConsumer): Consumer[] {
    const selectedConsumerType: Consumer[] = [];

    for (const [key, value] of Object.entries(type)) {
      if (value) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectedConsumerType.push({ type: key as any });
      }
    }
    return selectedConsumerType;
  }

  public mapDocumentDetails(
    documentDetailsWithFile?: DocumentDetailsWithFile,
    documentsUploadEnabled?: boolean
  ): DocumentDetails {
    // If documentsUploadEnabled is null or undefined, then don't overwrite the global value
    if (documentsUploadEnabled || documentsUploadEnabled === false) {
      this.#documentsUploadEnabled = documentsUploadEnabled;
    }
    if (this.#documentsUploadEnabled) {
      return {
        documents: this.#mapDocuments(documentDetailsWithFile?.documents),
        notes: documentDetailsWithFile?.notes,
      };
    } else {
      // add dummy document if document upload is disabled
      return this.#mapDummyDocumentDetails();
    }
  }

  #mapDummyDocumentDetails(): DocumentDetails {
    const availableDocumentTypes =
      this.#documentMapperService.getAvailableDocumentTypes(this.#formData!);
    const documents: Document[] = [];
    availableDocumentTypes.forEach(documentType => {
      if (documentType.required) {
        documents.push({
          type: documentType.typeName,
          name: `dummy_${documentType.typeName}`,
        });
      }
    });
    return {
      documents,
    };
  }

  #mapDocuments(documents?: DocumentWithFile[]): Document[] {
    return (
      documents?.map(document => {
        return {
          type: document.type,
          name: document.name,
        };
      }) ?? []
    );
  }

  #mapContact(formDataObject: PartialFormData): Contact {
    return {
      onBehalf: formDataObject.contact?.onBehalf!,
      onBehalfType: formDataObject.contact?.onBehalfType,
      powerOfAttorney: formDataObject.contact?.powerOfAttorney,
      ...(formDataObject.contact?.powerOfAttorneyDocument && {
        powerOfAttorneyDocument: {
          type: formDataObject.contact.powerOfAttorneyDocument.type,
          name: formDataObject.contact.powerOfAttorneyDocument.name,
        },
      }),
      requesterContact: this.#mapRequesterContact(formDataObject),
      differentAddress: formDataObject.contact?.differentAddress!,
      differentRequesterAddress: this.#mapDifferentAddress(formDataObject),
      onBehalfContact: this.#mapOnBehalfContact(formDataObject),
    };
  }

  #mapRequesterContact(formDataObject: PartialFormData): ContactDetails {
    if (formDataObject.contact?.onBehalf) {
      return formDataObject.contact.differentContactForm!;
    } else {
      return formDataObject.contact?.contactForm!;
    }
  }

  #mapDifferentAddress(formDataObject: PartialFormData): Address | null {
    if (formDataObject.contact?.differentAddress === true) {
      return formDataObject.contact.differentAddressForm!;
    } else {
      return null;
    }
  }

  #mapOnBehalfContact(formDataObject: PartialFormData): ContactDetails | null {
    if (formDataObject.contact?.onBehalf === true) {
      return formDataObject.contact.contactForm;
    } else {
      return null;
    }
  }
}
