<div class="section-fields-container">
  <app-summary-section-field
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.FLOW_RATE"
    [value]="constructionWaterRequirements.flowRate">
  </app-summary-section-field>

  <app-summary-section-field
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_CONVERTED_SPACE"
    [value]="constructionWaterRequirements.convertedSpace">
  </app-summary-section-field>

  <app-summary-section-field
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_INSTALLATION_DATE"
    [value]="
      (constructionWaterRequirements.desiredInstallationDate
        | date: 'shortDate' : '' : currentLanguageCulture)!
    ">
  </app-summary-section-field>

  <app-summary-section-field
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_DESIRED_DECONSTRUCTION_DATE"
    [value]="
      (constructionWaterRequirements.desiredDeconstructionDate
        | date: 'shortDate' : '' : currentLanguageCulture) || undefined
    ">
  </app-summary-section-field>

  <app-summary-section-field
    label="WATER.CONSTRUCTION_WATER_REQUIREMENTS.SUBHEADING_NOTES"
    [value]="constructionWaterRequirements.notes">
  </app-summary-section-field>
</div>
