<app-summary-section
  heading="PV_FACILITY_INFORMATION.HEADING"
  [stepRoute]="ROUTES.PV_FACILITY_INFORMATION">
  <p class="section-heading"></p>
  <div class="section-fields-container">
    @if (pvCustomerFacility(); as pvCustomerFacility) {
      <app-summary-section-field
        [value]="controllableConsumptionSystemsTranslated()"
        label="PV_FACILITY_INFORMATION.CONTROLLABLE_CONSUMPTION_DEVICE_LABEL">
      </app-summary-section-field>
      <app-summary-section-field
        label="PV_FACILITY_INFORMATION.CONTROL_ACCORDING_TO_§_9_EEG"
        [value]="'PV_CONTROL.' + pvCustomerFacility.control | translate">
      </app-summary-section-field>
      <app-summary-section-field
        label="PV_FACILITY_INFORMATION.PLANNED_COMMISSIONING_DATE"
        [value]="
          (pvCustomerFacility.plannedCommissioningDate
            | date: 'shortDate' : '' : currentLanguageCulture) || '-'
        ">
      </app-summary-section-field>
      <app-summary-section-field
        label="PV_FACILITY_INFORMATION.SELECTION_OF_METERING_POINT_OPERATOR"
        [value]="'PV_OPERATOR.' + pvCustomerFacility.operator | translate">
      </app-summary-section-field>
      <app-summary-section-field
        label="PV_FACILITY_INFORMATION.DESIRED_FORM_OF_SALE_OF_THE_FEED_IN"
        [value]="
          'FORM_OF_FEED_IN_SALE.' + pvCustomerFacility.formOfFeedInSale
            | translate
        ">
      </app-summary-section-field>
    }
  </div>
</app-summary-section>

<app-summary-section
  heading="PV_INVERTER_INFORMATION.HEADING"
  [stepRoute]="ROUTES.PV_INVERTER_INFORMATION">
  <div class="section-fields-container">
    @if (pvInverter(); as pvInverter) {
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.OPERATING_MODE"
        [value]="
          'PV_INVERTER_INFORMATION.OPERATING_MODE.' + pvInverter.operatingMode
            | translate
        " />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.ISOLATED_OPERATIONAL"
        [value]="
          pvInverter.isolatedOperational
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        " />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.SYSTEM_MANUFACTURER"
        [value]="pvInverter.systemManufacturer" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.SYSTEM_TYPE"
        [value]="pvInverter.systemType" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.SYSTEM_COUNT"
        [value]="pvInverter.systemCount" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.MAX_APPARENT_POWER"
        [value]="pvInverter.maxApparentPower" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.MAX_APPARENT_POWER_SUM"
        [value]="pvInverter.maxApparentPowerSum" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER"
        [value]="pvInverter.maxControllablePower" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.MAX_CONTROLLABLE_POWER_SUM"
        [value]="pvInverter.maxControllablePowerSum" />
      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.GRID_FEED_IN"
        [value]="
          pvInverter.gridFeedIn
            ? ('PV_INVERTER_INFORMATION.GRID_FEED_IN_' + pvInverter!.gridFeedIn
              | translate)
            : null
        " />

      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.METER_AVAILABLE"
        [value]="
          pvInverter.meterAvailable
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        " />

      <app-summary-section-field
        label="PV_INVERTER_INFORMATION.METER_NUMBER"
        [value]="pvInverter.meterNumber" />
    }
  </div>
</app-summary-section>

<app-summary-section
  heading="PV_GENERATION_UNIT_INFORMATION.HEADING"
  [stepRoute]="ROUTES.PV_GENERATION_UNIT_INFORMATION">
  <div class="section-fields-container">
    @if (pvGenerationUnit(); as pvGenerationUnit) {
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.PERFORMANCE_PV_MODULE_IN_KWP"
        [value]="pvGenerationUnit.powerPerModule" />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.MODULE_COUNT"
        [value]="pvGenerationUnit.moduleCount" />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.INSTALLATION_PLACE"
        [value]="
          'PV_GENERATION_UNIT_INFORMATION.' + pvGenerationUnit.installationPlace
            | translate
        " />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING"
        [value]="
          pvGenerationUnit.powerFlowMonitoring
            ? ('COMMON.YES' | translate)
            : ('COMMON.NO' | translate)
        " />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
        [value]="pvGenerationUnit.powerFlowMonitoringManufacturer" />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE"
        [value]="pvGenerationUnit.powerFlowMonitoringType" />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_TYPE_POWER"
        [value]="pvGenerationUnit.powerFlowMonitoringPower" />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS"
        [value]="
          pvGenerationUnit.networkAndSystemProtectionDetailsLocation
            ? ('PV_GENERATION_UNIT_INFORMATION.' +
                pvGenerationUnit.networkAndSystemProtectionDetailsLocation
              | translate)
            : null
        " />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.POWER_FLOW_MONITORING_MANUFACTURER"
        [value]="
          pvGenerationUnit.networkAndSystemProtectionDetailsManufacturer
        " />
      <app-summary-section-field
        label="PV_GENERATION_UNIT_INFORMATION.NETWORK_AND_SYSTEM_PROTECTION_DETAILS_TYPE"
        [value]="pvGenerationUnit.networkAndSystemProtectionDetailsType" />
    }
  </div>
</app-summary-section>
